import {EBillSettingsConfiguration} from "../../../shared/services/models/configuration.model";
import {asyncScheduler, forkJoin, lastValueFrom, Observable, scheduled, toArray} from "rxjs";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {
  LipoFormControlModel,
  LipoFormPassword,
  LipoFormSlideToggle,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {ZugFerDEdifactModel, ZugFerDMailModel, ZugFerDRestModel} from "../models/ebill/zug-ferd.model";

export async function setZugFerDRestBillSettings(
  model: ZugFerDRestModel,
  settings: EBillSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const observables = [
    getZugFerDRestBill(model, settings),
  ].map(ob$ => ob$.pipe(toArray()));

  const allFormGroups = await lastValueFrom(forkJoin(observables));

  if (detailModel) {
    allFormGroups.forEach(formGroups => {
      formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
    });
  }
}

export async function setZugFerDEdifactModelBillSettings(
  model: ZugFerDEdifactModel,
  settings: EBillSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const observables = [
    getZugFerDEdifactBill(model, settings),
  ].map(ob$ => ob$.pipe(toArray()));

  const allFormGroups = await lastValueFrom(forkJoin(observables));

  if (detailModel) {
    allFormGroups.forEach(formGroups => {
      formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
    });
  }
}

export async function setZugFerDMailModelBillSettings(
  model: ZugFerDMailModel,
  settings: EBillSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const observables = [
    getZugFerDMailBill(model, settings),
  ].map(ob$ => ob$.pipe(toArray()));

  const allFormGroups = await lastValueFrom(forkJoin(observables));

  if (detailModel) {
    allFormGroups.forEach(formGroups => {
      formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
    });
  }
}

export function getZugFerDRestBill(model: ZugFerDRestModel, settings: EBillSettingsConfiguration): Observable<LipoFormGroupModel> {
  const zugFerDRestFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.EBillID, Validators.required),
      key: 'EBillID',
      label: 'id'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantId, Validators.required),
      key: 'ParticipantId',
      label: 'Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantId),
      key: 'SubstitutParticipantId',
      label: 'Substitute Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantPartnerId),
      key: 'SubstitutParticipantPartnerId',
      label: 'Substitute Participant Partner ID'
    }),
    new LipoFormPassword({
      value: new FormControl(model.GatewayPassword, Validators.required),
      key: 'GatewayPassword',
      label: 'Gateway Password'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.RemotePath, Validators.required),
      key: 'RemotePath',
      label: 'Remote Path'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.BuyerAddressMapping, Validators.required),
      key: 'BuyerAddressMapping',
      label: 'Buyer Address Mapping'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShipToAddressMapping, Validators.required),
      key: 'ShipToAddressMapping',
      label: 'Ship To Address Mapping'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.AdditionalReference, Validators.required),
      key: 'AdditionalReference',
      label: 'Additional Reference'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.TargetFilename, Validators.required),
      key: 'TargetFilename',
      label: 'Target Filename'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.SkipCreditNotesProcessing),
      key: 'SkipCreditNotesProcessing',
      label: 'Skip Credit Notes Processing'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.SuppressOrderReference),
      key: 'SuppressOrderReference',
      label: 'Suppress Order Reference'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_${settings.zugFerDRest.formKey}_base`,
      label: 'ZUG FerD Rest',
      order: 4,
      controls: zugFerDRestFields,
      cardBackground: true,
    })
  ], asyncScheduler);
}

export function getZugFerDEdifactBill(model: ZugFerDEdifactModel, settings: EBillSettingsConfiguration): Observable<LipoFormGroupModel> {
  const zugFerDEdifactFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.EBillID, Validators.required),
      key: 'EBillID',
      label: 'id'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantId, Validators.required),
      key: 'ParticipantId',
      label: 'Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantId),
      key: 'SubstitutParticipantId',
      label: 'Substitute Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantPartnerId),
      key: 'SubstitutParticipantPartnerId',
      label: 'Substitute Participant Partner ID'
    }),
    new LipoFormPassword({
      value: new FormControl(model.GatewayPassword, Validators.required),
      key: 'GatewayPassword',
      label: 'Gateway Password'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.BuyerAddressMapping, Validators.required),
      key: 'BuyerAddressMapping',
      label: 'Buyer Address Mapping'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShipToAddressMapping, Validators.required),
      key: 'ShipToAddressMapping',
      label: 'Ship To Address Mapping'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.AdditionalReference),
      key: 'AdditionalReference',
      label: 'Additional Reference'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.TargetFilename, Validators.required),
      key: 'TargetFilename',
      label: 'Target Filename'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.SkipCreditNotesProcessing),
      key: 'SkipCreditNotesProcessing',
      label: 'Skip Credit Notes Processing'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.SuppressOrderReference),
      key: 'SuppressOrderReference',
      label: 'Suppress Order Reference'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_${settings.zugFerDEdifact.formKey}_base`,
      label: 'ZUG FerD Edifact',
      order: 5,
      controls: zugFerDEdifactFields,
      cardBackground: true,
    }),
  ], asyncScheduler);
}

export function getZugFerDMailBill(model: ZugFerDMailModel, settings: EBillSettingsConfiguration): Observable<LipoFormGroupModel> {
  const zugFerDMailFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.EBillID, Validators.required),
      key: 'EBillID',
      label: 'id'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantId, Validators.required),
      key: 'ParticipantId',
      label: 'Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantId),
      key: 'SubstitutParticipantId',
      label: 'Substitute Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantPartnerId),
      key: 'SubstitutParticipantPartnerId',
      label: 'Substitute Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ApiKey, Validators.required),
      key: 'ApiKey',
      label: 'API Key'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.RemotePath, Validators.required),
      key: 'RemotePath',
      label: 'Remote Path'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.BuyerAddressMapping, Validators.required),
      key: 'BuyerAddressMapping',
      label: 'Buyer Address Mapping'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShipToAddressMapping, Validators.required),
      key: 'ShipToAddressMapping',
      label: 'Ship To Address Mapping'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.SkipCreditNotesProcessing),
      key: 'SkipCreditNotesProcessing',
      label: 'Skip Credit Notes Processing'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.TargetFilename, Validators.required),
      key: 'TargetFilename',
      label: 'Target Filename'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.FilePattern, Validators.required),
      key: 'FilePattern',
      label: 'File Pattern'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.KeepFiles),
      key: 'KeepFiles',
      label: 'Keep Files'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.InvoiceMarker, Validators.required),
      key: 'InvoiceMarker',
      label: 'Invoice Marker'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_${settings.zugFerDMail.formKey}_base`,
      label: 'ZUG FerD Mail',
      order: 5,
      controls: zugFerDMailFields,
      cardBackground: true,
    }),
  ], asyncScheduler);
}
