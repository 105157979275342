export class YellowBillRestModel {
  readonly type: string = 'YellowBillRest'

  EBillID: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  SubstitutParticipantId: string = ''
  SubstitutParticipantPartnerId: string = ''
  GatewayPassword: string = ''
  RemotePath: string = ''
  BuyerAddressMapping: string = ''
  ShipToAddressMapping: string = ''
  TargetFilename: string = ''
  SkipCreditNotesProcessing: boolean = false
  SuppressOrderReference: boolean = false
  MapOrderReferenceToAccountAssignment: boolean = false
}

export class YellowBillMailModel {
  readonly type: string = 'YellowBillMail'

  EBillID: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  SubstitutParticipantId: string = ''
  SubstitutParticipantPartnerId: string = ''
  ApiKey: string = ''
  RemotePath: string = ''
  BuyerAddressMapping: string = ''
  ShipToAddressMapping: string = ''
  SkipCreditNotesProcessing: boolean = false
  TargetFilename: string = ''
  FilePattern: string = ''
  KeepFiles: boolean = false
  InvoiceMarker: string = ''
}
