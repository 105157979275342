@if (formModel) {
  <form class="form-container" [formGroup]="formModel.formGroup" (keydown.enter)="onSubmit.emit($event)">
    @for (group of formModel.baseForms; track $index) {
      <div class="form-group-container" [style.--group-container-columns]="formModel.columns">

        @if (group.expansionActive) {
          <mat-expansion-panel class="form-group-expansion" [hideToggle]="group.expansion.hideToggle"
                               [expanded]="group.expansion.expanded"
                               [disabled]="group.expansion.disabled"
                               [ngClass]="{'no-background': !group.expansion.background}">
            <mat-expansion-panel-header class="form-group-header-expansion"
                                        [ngClass]="{'remove-expansion-header': group.label === null && group.buttons.length === 0}">
              <div *ngTemplateOutlet="commonHeader"></div>
            </mat-expansion-panel-header>
            <div *ngTemplateOutlet="commonContent"></div>
          </mat-expansion-panel>

        } @else {
        <mat-card class="form-content-card" [ngClass]="{'no-background': !group.background, 'mat-card-with-background-tweak' : group.background}">
          <div class="form-group-header">
            <div *ngTemplateOutlet="commonHeader"></div>
          </div>
          <div *ngTemplateOutlet="commonContent"></div>
        </mat-card>
        }

      </div>

      <ng-template #commonHeader>
        <div class="form-group-header-title" *ngIf="group.label">
          <h2 class="mat-title-medium">{{ group.label | translate }}</h2>
        </div>
        <div class="form-group-header-actions" *ngIf="group.buttons && group.buttons.length > 0">
          @for (button of group.buttons; track $index) {
            <button mat-stroked-button (click)="button.onClick()" [class]="button.className">
              <mat-icon [fontSet]="button.iconFontSet">{{ button.icon }}</mat-icon>
              {{ button.text | translate | uppercase }}
            </button>
          }
        </div>
      </ng-template>

      <ng-template #commonContent>
        <div class="form-group-body" [formGroupName]="group.key" [style]="group.style" [style.--group-body-columns]="group.columns">
          @for (control of group.controls; track $index) {
            @switch (control.controlType) {
              @case (LipoFormControlTypeEnum.TEXT) {
                <mat-form-field class="form-field" [style]="control.style" [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete" matInput [placeholder]="control.placeholder | translate" [formControlName]="control.key">
                  @if (control.settings['showCopyIcon']) {
                    <button mat-icon-button matSuffix (click)="control.settings['onCopyIconClick'](control)" [matTooltip]="'tooltip.copyProxyLink' | translate">
                      <mat-icon>content_copy</mat-icon>
                    </button>
                  }
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.PASSWORD) {
                <mat-form-field [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete" matInput [type]="control.settings['visible'] ? 'text' : 'password'"
                         [formControlName]="control.key"
                         [placeholder]="control.placeholder"
                         [readonly]="shouldDisablePasswordField(control)"/>
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="clickPasswordIconEvent($event, control)"
                    [attr.aria-label]="'hide_password' | translate"
                    [attr.aria-pressed]="!control.settings['visible']"
                    [matTooltip]="'tooltip.togglePasswordVisibility' | translate"
                    [hidden]="shouldDisablePasswordField(control)"
                  >
                    <mat-icon>{{ control.settings['visible'] ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
                  @if (control.settings['showResetIcon']) {
                    <button mat-icon-button matSuffix (click)="control.settings['onResetIconClick'](control)"
                            [attr.aria-label]="'password.reset' | translate"
                            [matTooltip]="'tooltip.resetPassword' | translate"
                    >
                      <mat-icon>lock_reset</mat-icon>
                    </button>
                  }
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>

              }
              @case (LipoFormControlTypeEnum.TEXTAREA) {
                <mat-form-field [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <textarea matInput [formControlName]="control.key"
                            [rows]="control.settings['rows']"
                            [placeholder]="control.placeholder"
                  ></textarea>
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.NUMERIC) {
                <mat-form-field [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete" matInput type="number" [formControlName]="control.key"
                         [min]="control.settings['min']"
                         [max]="control.settings['max']"
                         [placeholder]="control.placeholder"
                         TokenFormatInput
                  >
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.PHONE) {
                <mat-form-field [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete" matInput type="tel" [formControlName]="control.key" [placeholder]="control.placeholder">
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.MAIL) {
                <mat-form-field [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete" matInput type="email" [formControlName]="control.key"
                         [placeholder]="control.placeholder"
                  >
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.SELECT) {
                <mat-form-field [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <mat-select [formControlName]="control.key" [multiple]="control.settings['multiSelect']">
                    @for (option of control.settings['options']; track option) {
                      <mat-option [value]="option.value">{{ option.key | translate }}</mat-option>
                    }
                  </mat-select>
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.CHECKBOX) {
                <mat-checkbox [formControlName]="control.key">{{ control.label | translate }}</mat-checkbox>
              }
              @case (LipoFormControlTypeEnum.SLIDE_TOGGLE) {
                <mat-slide-toggle [formControlName]="control.key">{{ control.label | translate }}</mat-slide-toggle>
              }
              @case (LipoFormControlTypeEnum.RADIO) {
                <mat-radio-group [formControlName]="control.key">
                  @for (option of control.settings['options']; track option) {
                    <mat-radio-button [value]="option.value">{{ option.key }}</mat-radio-button>
                  }
                </mat-radio-group>
              }
              @case (LipoFormControlTypeEnum.DATE) {
                <mat-form-field [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete" matInput [matDatepicker]="picker1" [placeholder]="control.placeholder"
                         [formControlName]="control.key">
                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.COLOR) {
                <mat-form-field floatLabel="always" [style.min-width]="control.minWidth" [style.--form-field-columns]="control.columns" [style.--form-field-columns-flex]="control.flex">
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete" matInput [formControlName]="control.key"
                         [placeholder]="control.placeholder"
                         (change)="setColorPicker($event, control!)"/>
                  <ngx-colors
                    matSuffix
                    ngx-colors-trigger
                    [format]="colorFormat"
                    [formControlName]="control.key"
                    (change)="setColorInput($event, control!)"
                  ></ngx-colors>
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
              @case (LipoFormControlTypeEnum.AUTOCOMPLETE) {
                <mat-form-field>
                  <mat-label>{{ control.label | translate }}</mat-label>
                  <input [autocomplete]="control.autocomplete"
                    matInput
                    [formControlName]="control.key"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="control.settings['displayFn']">
                    @for (option of control.settings['filteredOptions']; track option) {
                      <mat-option [value]="option.value">{{ option.key | translate }}</mat-option>
                    }
                  </mat-autocomplete>
                  <mat-hint>{{ control.hint | translate }}</mat-hint>
                  <mat-error *ngIf="hasError(control)">
                    {{ getError(control!) | translate }}
                  </mat-error>
                </mat-form-field>
              }
            }
          }
        </div>
      </ng-template>

    }
</form>
}
