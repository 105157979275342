<table class="license-overview-card-table">
  <tr>
    <th>{{ 'tenant' | translate }}</th>
    <td>{{ appLicenseOverviewModel.tenant.sapCompanyDB }}</td>
  </tr>
  <tr>
    <th>{{ 'purchaseDate' | translate }}</th>
    <td>{{ appLicenseOverviewModel.purchaseDate.toDate() | date }}</td>
  </tr>
  <tr>
    <th>{{ 'expiryDate' | translate }}</th>
    <td>{{ appLicenseOverviewModel.expiryDate.toDate() | date }}</td>
  </tr>
</table>
