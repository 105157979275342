import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";
import {ProductOverviewModel} from "../components/models/product-overview.model";
import {
  ProductOverviewCardBodyComponent
} from "../components/product-overview-card-body/product-overview-card-body.component";
import {Router} from "@angular/router";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../shared/components/models/lipo-table.model";
import {LipoTableImageComponent} from "../../shared/components/lipo-table-image/lipo-table-image.component";
import {MatTableDataSource} from "@angular/material/table";
import {LipoModelInterface} from "../../shared/interfaces/lipo-model.interface";

export class ProductOverviewMapper {
  public static toLipoCardModel(productOverviewModel: ProductOverviewModel): LipoCardModel {
    return new LipoCardModel({
      id: productOverviewModel.id,
      route: {commands: [LipoRouteEnum.PRODUCT, productOverviewModel.id]},
      title: productOverviewModel.name,
      body: {component: ProductOverviewCardBodyComponent, inputs: {productOverviewModel: productOverviewModel}},
      logoUrl: productOverviewModel.image
    });
  }

  public static toLipoTable(productOverviewModel: ProductOverviewModel[], router: Router): LipoTableModel<any> {
    let updatedValues = productOverviewModel.map(product => {
      return {
        ...product,
        lipoTableImageComponentInputs: {logoUrl: product.image} as LipoTableImageComponent,
        getId: () => product.id,
      };
    });

    let tableDataSource = new MatTableDataSource<LipoModelInterface>(updatedValues);
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: '', PropertyName: 'lipoTableImageComponentInputs', Component: LipoTableImageComponent},
      {HeaderCellName: 'products', PropertyName: 'name'},
      {HeaderCellName: 'description', PropertyName: 'description'},
    );

    return new LipoTableModel(
      tableDataSource,
      displayedColumns,
      undefined,
      undefined,
      async (value: LipoModelInterface) => {
        await router.navigate([LipoRouteEnum.PRODUCT, value.getId()])
      },
      {header: true, headerAction: false}
    );
  }
}
