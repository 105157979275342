export class PartnerOverviewModel {
  constructor(
    public id: number | null,
    public name: string,
    public phone: string,
    public logoUrl: string | null,
    public mail: string,
    public customers: number,
    public systems: number,
    public licenses: number,
  ) { }
}
