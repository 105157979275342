import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {ConfigurationService} from "../../../shared/services/configuration.service";
import {AppLicenseSettingsConfiguration} from "../../../shared/services/models/configuration.model";
import {TenantAppSettingsDetailMapper} from "../../mappers/tenant-app-settings-detail.mapper";
import {getTenantAppSettingsDetailFormFields} from "./tenant-app-settings-detail.form-fields";
import {TenantServiceModel} from "../../services/models/tenant-service.model";
import {SystemDataService} from "../../../system/services/system-data.service";
import {SystemServiceModel} from "../../../system/services/models/system-service.model";
import {TenantAppSettingsDataService} from "../../services/tenant-app-settings-data.service";
import {TenantAppSettingsServiceMapper} from "../../mappers/tenant-app-settings-service.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {ProductServiceModel} from "../../../product/services/models/product-service.model";
import {ProductDataService} from "../../../product/services/product-data.service";
import {ProgressService} from "../../../shared/services/progress.service";
import {TenantAppSettingsServiceModel} from "../../services/models/tenant-app-settings-service.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";

@Component({
  selector: 'du-tenant-app-setting-detail',
  standalone: true,
  imports: [
    LipoDetailComponent,
  ],
  templateUrl: './tenant-app-settings-detail.component.html',
  styleUrl: './tenant-app-settings-detail.component.scss'
})
export class TenantAppSettingsDetailComponent implements OnInit {
  protected readonly LipoRouteEnum = LipoRouteEnum;

  private products: ProductServiceModel[] = []

  detailModel?: LipoDetailModel;
  systemId: number | null = null;
  tenantId: number | null = null;
  isSaving: boolean = false
  tenantAppSettingsId: number | null = null;
  oldTenant: TenantServiceModel | null = null;
  appLicenseSettings: AppLicenseSettingsConfiguration
  isCreateItem: boolean = false;
  headerButtons: LipoButton[] = []

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _translationService: TranslateService,
    private _productDataService: ProductDataService,
    private _appSettingsService: TenantAppSettingsDataService,
    private _systemService: SystemDataService,
    private _progressService: ProgressService,
    _config: ConfigurationService
  ) {
    this.appLicenseSettings = _config.configuration.appLicenseSettings
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.handleRouteData(data);
      }
    });
  }

  private handleRouteData(data: Data): void {
    this.isCreateItem = (data as LipoRoutesDataModel).isCreateItem ?? false;
    this.handleParams();
    this.handleTenantAppSettingData();
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.systemId = this.idToNumber(params.get('systemId'));
      this.tenantId = this.idToNumber(params.get('tenantId'));
      this.tenantAppSettingsId = this.idToNumber(params.get('tenantAppSettingId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handleTenantAppSettingData(): void {
    if (this.systemId !== null) {
      this._systemService.getSystem(this.systemId).subscribe({
        next: system => this.processSystemData(system)
      });
    }
  }

  private processSystemData(system: SystemServiceModel): void {
    const oldTenant = system.tenants.find(tenant => tenant.id === this.tenantId) ?? null;
    const productIds = oldTenant?.appLicenses?.map(appLicense => appLicense.productId) ?? [];

    this._productDataService.getProductsByIds(productIds).subscribe({
      next: products => {

        products = this.manipulateEBillConfigs(products);
        products = this.manipulateAribaConfigs(products);

        if (this.isCreateItem) {
          this.products = products.filter(product => !oldTenant?.appSettings.map(item => item.appName).includes(product.name));
        } else {
          this.products = products.filter(product => oldTenant?.appSettings.map(item => item.appName).includes(product.name));
        }
      },
      complete: () => {
        this.oldTenant = oldTenant;
        this.isCreateItem ? this.createEmptyTenantAppSetting() : this.loadTenantTenantAppSetting(this.oldTenant);
      }
    })
  }

  manipulateEBillConfigs(products: ProductServiceModel[]): ProductServiceModel[] {
    const eBillConfig = this.appLicenseSettings.eBill;
    const variants = [
      eBillConfig.yellowBillRest,
      eBillConfig.yellowBillMail,
      eBillConfig.zugFerDRest,
      eBillConfig.zugFerDMail,
      eBillConfig.zugFerDEdifact
    ];

    return this.manipulateProductConfigs(products, {
      formKey: eBillConfig.formKey,
      variants
    });
  }

  manipulateAribaConfigs(products: ProductServiceModel[]): ProductServiceModel[] {
    const aribaConfig = this.appLicenseSettings.ariba;
    const variants = [
      aribaConfig.inbound,
      aribaConfig.outbound
    ];

    return this.manipulateProductConfigs(products, {
      formKey: aribaConfig.formKey,
      variants
    });
  }

  manipulateProductConfigs(products: ProductServiceModel[], config: {
    formKey: string,
    variants: Array<{formKey: string}>
  }): ProductServiceModel[] {
    const baseProduct = products.find(product =>
      product.name.includes(config.formKey)
    );

    if (!baseProduct) {return products}

    const variantProducts = config.variants.map(variant => {
      const newProduct: ProductServiceModel = {...baseProduct};
      newProduct.name = `${config.formKey} - ${variant.formKey}`;
      return newProduct;
    });

    return [
      ...products.filter(product => product.name !== config.formKey),
      ...variantProducts
    ];
  }

  createEmptyTenantAppSetting(): void {
    let emptyTenantModel = new TenantAppSettingsDetailModel();
    this.createDetail(emptyTenantModel);
  }

  loadTenantTenantAppSetting(tenant: TenantServiceModel | null): void {
    let appSettings = tenant?.appSettings.find(appSetting => appSetting.id === this.tenantAppSettingsId);
    if (appSettings) {
      this.createDetail(TenantAppSettingsServiceMapper.toTenantAppSettingsDetailModel(appSettings))
    } else {
      this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId])
        .then(() => this._snackBar.Warning(this._translationService.instant('snackbar.notfound')));
    }
  }

  createDetail(appSettings: TenantAppSettingsDetailModel): void {
    let formModel = new LipoFormModel([])
    this.detailModel = TenantAppSettingsDetailMapper.toLipoDetailModel(appSettings, formModel)
    getTenantAppSettingsDetailFormFields(appSettings, this.appLicenseSettings, this.products, this.detailModel, this.headerButtons)
    this._progressService.stopLoading()
  }

  onDeleteClick(): void {
    if (this.tenantAppSettingsId !== null && this.systemId !== null && this.tenantId !== null) {
      this._appSettingsService.deleteAppSettings(this.systemId, this.tenantId, this.tenantAppSettingsId).subscribe({
        next: tenant => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, tenant.id])
          .then(() => this._snackBar.Deleted())
      });
    }
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form || !this.systemId || this.oldTenant === null) return;
    this.isSaving = true

    let tenantSettingsModel = LipoFormMapper.toTenantAppSettingsServiceModel(detailModel.form.formGroup, this.tenantAppSettingsId, this.appLicenseSettings)

    if (this.isCreateItem) {
      this.saveTenant(this.systemId!, this.oldTenant!.id!, tenantSettingsModel)
    } else {
      this.updateTenant(this.systemId, this.oldTenant.id!, tenantSettingsModel)
    }
  }

  saveTenant(systemId: number, oldTenantId: number, tenantAppSettingsModel: TenantAppSettingsServiceModel): void {
    this._appSettingsService.createAppSettings(systemId, oldTenantId, tenantAppSettingsModel).subscribe({
      next: tenantAppSettings => {
        this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId,
          LipoRouteEnum.APP_SETTINGS, tenantAppSettings.id]).then(() => this._snackBar.Saved())
      },
      error: () => this.isSaving = false,
      complete: () => this.isSaving = false
    });
  }

  updateTenant(systemId: number, oldTenantId: number, tenantAppSettingsModel: TenantAppSettingsServiceModel): void {
    this._appSettingsService.updateAppSettings(systemId, oldTenantId, tenantAppSettingsModel).subscribe({
      next: tenantAppSettings => {
        this.createDetail(TenantAppSettingsServiceMapper.toTenantAppSettingsDetailModel(tenantAppSettings));
        this._snackBar.Saved();
      },
      error: () => this.isSaving = false,
      complete: () => this.isSaving = false
    });
  }

}
