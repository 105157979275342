import {Component, Input} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {NgClass} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'du-system-heartbeat-status',
  templateUrl: './system-heartbeat-status.component.html',
  styleUrls: ['./system-heartbeat-status.component.scss'],
  imports: [
    MatIcon,
    NgClass,
    MatTooltip,
    TranslateModule
  ],
  standalone: true
})
export class SystemHeartbeatStatusComponent {
  @Input() isAlive: boolean = false;
}
