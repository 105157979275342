import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";
import {
  AppLicenseSettingsConfiguration,
  AribaSettingsConfiguration,
  EBillSettingsConfiguration
} from "../../../shared/services/models/configuration.model";
import {pairwise, startWith} from "rxjs";
import {
  LipoFormControlModel,
  LipoFormSelect,
  LipoFormTextArea
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {ProductServiceModel} from "../../../product/services/models/product-service.model";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {fromStrDict} from "../../../utils/str-dict";
import {setMobileLogisticSettings} from "./mobile-logistic-settings.form-fields";
import {setStoreLinkSettings} from "./store-link-settings-form-fields";
import {MobileLogisticsSettingsModel} from "../models/mobile-logistics-settings.model";
import {StoreLinkSettingsServiceModel} from "../models/store-link-settings.model";
import {YellowBillMailModel, YellowBillRestModel} from "../models/ebill/yellow-bill.model";
import {setYellowMailBillSettings, setYellowRestBillSettings} from "./yellow-bill-settings-form-fields";
import {
  setZugFerDEdifactModelBillSettings,
  setZugFerDMailModelBillSettings,
  setZugFerDRestBillSettings
} from "./zugferd-bill-settings-form-fields";
import {ZugFerDEdifactModel, ZugFerDMailModel, ZugFerDRestModel} from "../models/ebill/zug-ferd.model";
import {SetAribaInboundSettings, SetAribaOutboundSettings} from "./ariba-settings.form-fields";
import {AribaInboundModel, AribaOutboundModel} from "../models/ariba.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {TransferSFTPModel} from "../models/transfereSFTP.model";
import {setTransferSFTPSettings} from "./transfere-sftp-settings.form-fields";

const manualDefaultButton = {
  text: 'manual',
  icon: 'description',
  onClick: async () => {}
} as LipoButton

export function getTenantAppSettingsDetailFormFields(
  model: TenantAppSettingsDetailModel,
  appSettingsConfig: AppLicenseSettingsConfiguration,
  products: ProductServiceModel[] = [],
  detailModel: LipoDetailModel,
  headerButtons: LipoButton[]
) {
  let appNameHasValue = model.appName !== null && model.appName !== undefined;
  let appNameControl = new FormControl({value: model.appName, disabled: appNameHasValue}, [Validators.required])

  const appSettingsFields: LipoFormControlModel[] = [
    new LipoFormSelect({
      value: appNameControl,
      key: 'appName',
      label: 'appName',
      settings: {
        options: products.map(product => ({key: product.name, value: product.name})),
      },
    }),
    new LipoFormTextArea({
      value: new FormControl(model.description),
      key: 'description',
      label: 'description',
    }),
  ]

  detailModel.form?.addGroup(new LipoFormGroupModel({
    key: appSettingsConfig.formKey,
    label: 'appSettings',
    order: 1,
    controls: appSettingsFields,
    cardBackground: true,
  }))

  if (appNameControl.value !== null) {
    addAppNameControl(appNameControl.value, appSettingsConfig, detailModel, model.attributes, headerButtons).then()
  } else {
    appNameControl.valueChanges?.pipe(
      startWith(null),
      pairwise()
    ).subscribe(([previousAppNameValue, currentAppNameValue]: [(string | null), (string | null)]) => {
      if(previousAppNameValue === currentAppNameValue) return;
      removeAppNameSettingsFromForm([previousAppNameValue], appSettingsConfig.formKey, headerButtons, detailModel);
      addAppNameControl(currentAppNameValue, appSettingsConfig, detailModel, model.attributes, headerButtons).then();
    });
  }
}

/**
 * Adds and configures the application name control based on the provided parameters.
 *
 * @param {string | null} appNameValue - The value of the application name to control.
 * @param {AppLicenseSettingsConfiguration} appSettings - The application license settings configuration object.
 * @param {LipoDetailModel} detailModel - The detail model associated with the application.
 * @param {any | null} attributes - Additional attributes required for the control, or null if not provided.
 * @param headerButtons - Buttons represented in the header
 * @return {Promise<void>} A promise that resolves when the control operations are completed.
 */
export function addAppNameControl(appNameValue: string | null, appSettings: AppLicenseSettingsConfiguration, detailModel: LipoDetailModel, attributes: any | null, headerButtons: LipoButton[]): Promise<void> {
  switch (appNameValue) {
    case appSettings.mobileLogistic.formKey: {
      const mobileLogisticsSettingsModel = createSettingsModel<MobileLogisticsSettingsModel>(MobileLogisticsSettingsModel, attributes);
      headerButtons.push(...addManualButton(appSettings.mobileLogistic.manualLink))
      return setMobileLogisticSettings(mobileLogisticsSettingsModel, appSettings.mobileLogistic, detailModel);
    }

    case appSettings.storeLink.formKey: {
      const storeLinkSettingsModel = createSettingsModel<StoreLinkSettingsServiceModel>(StoreLinkSettingsServiceModel, attributes);
      headerButtons.push(...addManualButton(appSettings.storeLink.manualLink))
      return setStoreLinkSettings(
        storeLinkSettingsModel,
        appSettings.storeLink, detailModel);
    }

    case appSettings.transferSFTP.formKey: {
      const transferSFTPSettingsModel = createSettingsModel<TransferSFTPModel>(TransferSFTPModel, attributes);
      headerButtons.push(...addManualButton(appSettings.transferSFTP.manualLink))
      return setTransferSFTPSettings(
        transferSFTPSettingsModel,
        appSettings.transferSFTP, detailModel);
    }

    default: {
      if (appNameValue?.includes(appSettings.eBill.formKey)) {
        return handleEBillAppName(appNameValue, detailModel, appSettings.eBill, attributes, headerButtons);
      } else if (appNameValue?.includes(appSettings.ariba.formKey)) {
        return handleAribaAppName(appNameValue, detailModel, appSettings.ariba, attributes, headerButtons);
      }
      return Promise.resolve();
    }
  }
}

function addManualButton(url: string): LipoButton[]  {
  if(url.length === 0) return []
  return [{...manualDefaultButton, onClick: async () => window.open(url, '_blank')
  }]
}

function handleEBillAppName(appNameValue: string, detailModel: LipoDetailModel, eBillConfig: EBillSettingsConfiguration, attributes: any, headerButtons: LipoButton[]): Promise<void> {
  if (appNameValue?.includes(eBillConfig.yellowBillRest.formKey)) {
    headerButtons.push(...addManualButton(eBillConfig.yellowBillRest.manualLink))
    return setYellowRestBillSettings(createSettingsModel<YellowBillRestModel>(YellowBillRestModel, attributes), eBillConfig, detailModel)
  } else if (appNameValue?.includes(eBillConfig.yellowBillMail.formKey)) {
    headerButtons.push(...addManualButton(eBillConfig.yellowBillMail.manualLink))
    return setYellowMailBillSettings(createSettingsModel<YellowBillMailModel>(YellowBillMailModel, attributes), eBillConfig, detailModel)
  } else if (appNameValue?.includes(eBillConfig.zugFerDRest.formKey)) {
    headerButtons.push(...addManualButton(eBillConfig.zugFerDRest.manualLink))
    return setZugFerDRestBillSettings(createSettingsModel<ZugFerDRestModel>(ZugFerDRestModel, attributes), eBillConfig, detailModel)
  } else if (appNameValue?.includes(eBillConfig.zugFerDMail.formKey)) {
    headerButtons.push(...addManualButton(eBillConfig.zugFerDMail.manualLink))
    return setZugFerDMailModelBillSettings(createSettingsModel<ZugFerDMailModel>(ZugFerDMailModel, attributes), eBillConfig, detailModel)
  } else if (appNameValue?.includes(eBillConfig.zugFerDEdifact.formKey)) {
    headerButtons.push(...addManualButton(eBillConfig.zugFerDEdifact.manualLink))
    return setZugFerDEdifactModelBillSettings(createSettingsModel<ZugFerDEdifactModel>(ZugFerDEdifactModel, attributes), eBillConfig, detailModel)
  }
  return Promise.resolve();
}

function handleAribaAppName(appNameValue: string, detailModel: LipoDetailModel, aribaConfig: AribaSettingsConfiguration, attributes: any, headerButtons: LipoButton[]): Promise<void> {
  if (appNameValue?.includes(aribaConfig.inbound.formKey)) {
    headerButtons.push(...addManualButton(aribaConfig.inbound.manualLink))
    return SetAribaInboundSettings(createSettingsModel<AribaInboundModel>(AribaInboundModel, attributes), aribaConfig, detailModel)
  } else if (appNameValue?.includes(aribaConfig.outbound.formKey)) {
    headerButtons.push(...addManualButton(aribaConfig.outbound.manualLink))
    return SetAribaOutboundSettings(createSettingsModel<AribaOutboundModel>(AribaOutboundModel, attributes), aribaConfig, detailModel)
  }
  return Promise.resolve();
}

export function removeAppNameSettingsFromForm(appSettingsName: (string | null)[] = [], appSettingsFormKey: string, headerButtons: LipoButton[], detailModel?: LipoDetailModel): void {
  if (detailModel === undefined) return;
  while (headerButtons.length > 0) {
    headerButtons.pop();
  }
  for (let appSettingName of appSettingsName) {
    if (appSettingName !== null) {
      let allGroupsOfType = Object.keys(detailModel.form?.formGroup?.controls || {}).filter(key => key !== appSettingsFormKey);
      allGroupsOfType.forEach(key => {
        detailModel.form?.removeGroup(key)
      })
    }
  }
}

function createSettingsModel<T>(Ctr: new () => T, obj: { [key: string]: string } | null): T {
  if (obj === null) {
    return new Ctr();
  }
  return fromStrDict<T>(obj);
}
