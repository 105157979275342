<div class="action-container">
  @for (badge of badges; track $index) {
    <div class="action-item">
      <div class="action-amount-container">
        <p class="action-amount action-amount-border">{{ badge.amount }}</p>
      </div>
      <div>
        <a class="card-action-type"
           [ngClass]="{'no-underline': !badge.routerLink}"
           [routerLink]="badge.routerLink">{{ badge.label | translate }}</a>
      </div>
    </div>
  }
</div>
