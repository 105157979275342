<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div mat-dialog-title class="dialog-header">
    <button mat-icon-button class="close-button" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="dialog-title">{{ 'dialogRequired' | translate }}</div>
  </div>

  <mat-dialog-content class="tenant-detail-dialog">
    <form [formGroup]="tenantForm" class="form-column">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'sapUser' | translate }}</mat-label>
        <input matInput formControlName="sapUser" />
        <mat-error>
          @let newPasswordCtrl = tenantForm.get('sapUser');
          @if(newPasswordCtrl?.hasError('required') && newPasswordCtrl?.dirty) {
            {{ 'error.required' | translate }}
          }
        </mat-error>
      </mat-form-field>

      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'sapPassword' | translate }}</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="sapPassword" [readonly]="isPrefixedPassword"/>
        <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button" aria-label="Toggle password visibility" [matTooltip]="'tooltip.togglePasswordVisibility' | translate" [hidden]="isPrefixedPassword">
          <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
          <mat-error>
              @let sapPasswordCtrl = tenantForm.get('sapPassword');
              @if(sapPasswordCtrl?.hasError('required') && sapPasswordCtrl?.dirty) {
                  {{ 'error.required' | translate }}
              }
          </mat-error>
      </mat-form-field>
      @if(data.additionalConfirmation) {
        <p class="mat-body">{{ data.additionalConfirmation }}</p>
      }
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()">{{ 'button.cancel' | translate }}</button>
    <button mat-flat-button (click)="onSave()" [disabled]="tenantForm.invalid">{{ 'button.submit' | translate }}</button>
  </mat-dialog-actions>
</div>
