import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {LipoDetailModel} from "../../models/lipo-detail.model";
import {MatCardModule} from "@angular/material/card";
import {MatTabsModule} from "@angular/material/tabs";
import {LipoDetailTableComponent} from "../lipo-table/lipo-detail-table.component";
import {LipoFormComponent} from "../lipo-form/lipo-form.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Location, NgClass, NgComponentOutlet, NgIf, NgOptimizedImage, UpperCasePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {LipoConfirmationDialogComponent} from "../lipo-confirmation-dialog/lipo-confirmation-dialog.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {isLipoTableModel, isTypeAny} from "../../../utils/type-check";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {LipoRoutesDataModel} from "../../models/lipo-routes-data.model";
import {LipoDetailSkeletonComponent} from "../lipo-detail-skeleton/lipo-detail-skeleton.component";
import {ProgressService} from "../../services/progress.service";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {LipoReadonlyComponent} from "../lipo-readonly/lipo-readonly.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {LipoButton} from "../models/lipo-button";
import {LipoBreadcrumbsComponent} from "../lipo-breadcrumbs-menu/lipo-breadcrumbs.component";

@Component({
  selector: 'du-lipo-detail',
  standalone: true,
  imports: [
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    LipoFormComponent,
    LipoDetailTableComponent,
    NgOptimizedImage,
    TranslateModule,
    NgIf,
    NgComponentOutlet,
    UpperCasePipe,
    LipoDetailSkeletonComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    LipoReadonlyComponent,
    MatProgressSpinner,
    LipoBreadcrumbsComponent,
    LipoBreadcrumbsComponent,
    NgClass,
  ],
  templateUrl: './lipo-detail.component.html',
  styleUrl: './lipo-detail.component.scss'
})

export class LipoDetailComponent implements OnInit {
  @Input() detailModel?: LipoDetailModel;
  @Input() navigateBack?: { commands: any[], extras?: NavigationExtras };
  @Input() hideDeleteButton?: boolean = false;
  @Input() showLicenseWizardButton: boolean = false;
  @Input() hideSaveButton?: boolean = false;
  @Input() isReadOnly?: boolean = false;
  @Input() headerButtons: LipoButton[] = [];
  @Input() menuButtons: LipoButton[] = [];

  @Input()
  set isSaving(value: boolean) {
    if (this._isSaving && !value) {
      let form = this.detailModel?.form;
      if (form && form.formGroup) {
        form.formGroup.markAsPristine();
      }
    }
    this._isSaving = value;
  }

  @Output() onSaveClicked = new EventEmitter();
  @Output() onDeleteClicked = new EventEmitter();
  @Output() onLicenseWizardClicked = new EventEmitter();

  protected _isSaving: boolean = false

  get isFormValid(): boolean | undefined {
    let form = this.detailModel?.form;
    return form?.formGroup.valid && form?.formGroup.dirty;
  }

  private isCreate: boolean = false;

  protected readonly _progressService = inject(ProgressService);

  constructor(
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        this.isCreate = routesDataModel.isCreateItem || false;
      }
    });

    if (!this.hideDeleteButton && !this.isCreate) {
      this.menuButtons.unshift(
        new LipoButton(
          "button.delete",
          async () => this.onDeleteIconClicked(),
          undefined,
          "delete",
        ))
    }
  }

  getSubtitle(): string {
    if (this.detailModel?.subtitle) {
      return this.detailModel?.subtitle;
    } else {
      return this.detailModel?.id ? `#${this.detailModel.id}` : '';
    }
  }

  async onSubmitTriggered(): Promise<void> {
    if (this.isFormValid) {
      this.onSaveClicked.emit();
    }
  }

  onDeleteIconClicked(): void {
    const dialogRef = this._dialog.open(LipoConfirmationDialogComponent, {
      data: {
        title: this._translate.instant('dialog.title.delete'),
        content: this._translate.instant('dialog.content.delete'),
        confirmButtonText: this._translate.instant('button.delete')
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result === true) {
          this.onDeleteClicked.emit();
        }
      }
    });
  }

  async handleNavigateBack() {
    if (this.navigateBack) {
      await this._router.navigate(this.navigateBack.commands, this.navigateBack.extras);
    } else {
      this._location.back();
    }
  }

  protected readonly isTypeAny = isTypeAny;
  protected readonly isLipoTableModel = isLipoTableModel;
}
