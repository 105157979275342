import {
  ShopifySettingsModel,
  ShopwareSettingsModel,
  StoreLinkSettingsModel,
  StoreLinkSettingsServiceModel
} from "../components/models/store-link-settings.model";

export function mapToStoreLinkSettingsModel(serviceModel: StoreLinkSettingsServiceModel): StoreLinkSettingsModel {
    return new StoreLinkSettingsModel(
        serviceModel.JSONataHost,
        serviceModel.JSONataUser,
        serviceModel.JSONataPassword,
        serviceModel.JsonPath,
        serviceModel.DefaultPriceList ?? null,
        serviceModel.DefaultWarehouse ?? null,
        serviceModel.PrePaymentCreateReservedInvoice === "Y",
        serviceModel.PrePaymentCreateDownPayment === "Y",
        serviceModel.PrePaymentCreateIncomingPayment === "Y",
    );
}

export function mapToShopwareSettingsModel(serviceModel: StoreLinkSettingsServiceModel): ShopwareSettingsModel {
    return {
        ShopwareHost: serviceModel.ShopwareHost ?? '',
        ShopwareTokenUrl: serviceModel.ShopwareTokenUrl ?? '',
        ShopwareTokenUser: serviceModel.ShopwareTokenUser ?? '',
        ShopwareTokenPassword: serviceModel.ShopwareTokenPassword ?? '',
        ShopwareSyncApiUrl: serviceModel.ShopwareSyncApiUrl ?? '',
        CreateContainerProductForVariantGroup: serviceModel.CreateContainerProductForVariantGroup == 'Y',
        IsB2B: serviceModel.IsB2B == 'Y'
    };
}

export function mapToShopifySettingsModel(serviceModel: StoreLinkSettingsServiceModel): ShopifySettingsModel {
    return {
        ShopifyHost: serviceModel.ShopifyHost ?? '',
        ShopifyToken: serviceModel.ShopifyToken ?? '',
        ShopifyStockBatchSize: serviceModel.ShopifyStockBatchSize ?? 100,
        CreateBusinessPartnerFromSalesOrder: serviceModel.CreateBusinessPartnerFromSalesOrder == 'Y'
    };
}

export function mapToStoreLinkSettingsServiceModel(storeLinkSettingsModel: StoreLinkSettingsModel, shopwareSettingsModel: ShopwareSettingsModel, shopifySettingsModel: ShopifySettingsModel): StoreLinkSettingsServiceModel {
    return {
        JSONataHost: storeLinkSettingsModel.JSONataHost,
        JSONataUser: storeLinkSettingsModel.JSONataUser,
        JSONataPassword: storeLinkSettingsModel.JSONataPassword,
        JsonPath: storeLinkSettingsModel.JsonPath,
        DefaultPriceList: storeLinkSettingsModel.DefaultPriceList ?? undefined,
        DefaultWarehouse: storeLinkSettingsModel.DefaultWarehouse ?? undefined,
        PrePaymentCreateReservedInvoice: storeLinkSettingsModel.PrePaymentCreateReservedInvoice ? "Y" : "N",
        PrePaymentCreateDownPayment: storeLinkSettingsModel.PrePaymentCreateDownPayment ? "Y" : "N",
        PrePaymentCreateIncomingPayment: storeLinkSettingsModel.PrePaymentCreateIncomingPayment ? "Y" : "N",
        ShopwareHost: shopwareSettingsModel.ShopwareHost,
        ShopwareTokenUrl: shopwareSettingsModel.ShopwareTokenUrl,
        ShopwareTokenUser: shopwareSettingsModel.ShopwareTokenUser,
        ShopwareTokenPassword: shopwareSettingsModel.ShopwareTokenPassword,
        ShopwareSyncApiUrl: shopwareSettingsModel.ShopwareSyncApiUrl,
        CreateContainerProductForVariantGroup: shopwareSettingsModel.CreateContainerProductForVariantGroup ? "Y" : "N",
        IsB2B: shopwareSettingsModel.IsB2B ? "Y" : "N",
        ShopifyHost: shopifySettingsModel.ShopifyHost,
        ShopifyToken: shopifySettingsModel.ShopifyToken,
        ShopifyStockBatchSize: shopifySettingsModel.ShopifyStockBatchSize,
        CreateBusinessPartnerFromSalesOrder: shopifySettingsModel.CreateBusinessPartnerFromSalesOrder ? "Y" : "N"
    };
}
