<div class="banner-container">
  <img style="object-fit: cover" [ngSrc]="bannerPicture" alt="banner" fill priority>
</div>
<div class="container">
  <div class="grid-container" [style.--grid-gap-size]="'12px'">
    @if (profile) {
      <div class="col-span-12">
        <div style="display: flex; justify-content: center;">
          <mat-card>
            <mat-card-header style="display: flex; justify-content: center;">
              <p class="mat-title-large">
                {{ 'welcome' | translate }}
              </p>
            </mat-card-header>
            <mat-card-content>
              <div class="profile-container">
                <div class="profile-image-container">
                  <img [ngSrc]="profilePicture"
                       alt="account" height="55" width="55" priority>
                </div>
                <div class="profile-name-container">
                  <p class="profile-name mat-headline-medium">{{ profile.firstName }} {{ profile.lastName }}</p>
                  <p class="profile-mail mat-body-large">{{ profile.email }}</p>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </div>
    }

    <div class="col-span-12 navigation-card" [style.--grid-gap-size]="'12px'">
      @for (item of navigation; track item) {
        @if (isUserInRole(item.roles)) {
            <mat-card>
              <mat-card-header>
                  <mat-icon class="navigation-icon icon-color"
                            [fontSet]="item.fontSet">{{ item.icon }}
                  </mat-icon>
                  <h3 class="mat-title-large">{{ item.name | translate }}</h3>
              </mat-card-header>
              <mat-card-content>
                <p class="mat-body-medium">
                  {{ item.description | translate }}
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button [routerLink]="item.link" mat-button>
                  {{ 'goto' | translate: {location: (item.name | translate)} }}
                </button>
              </mat-card-actions>
            </mat-card>
        }
      }
    </div>


  </div>
</div>
