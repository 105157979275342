import {Component, OnInit} from '@angular/core';
import {CustomerDataService} from "../../services/customer-data.service";
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {Router} from "@angular/router";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {CustomerServiceMapper} from "../../mappers/customer-service.mapper";
import {CustomerOverviewMapper} from "../../mappers/customer-overview.mapper";
import {ProgressService} from "../../../shared/services/progress.service";
import {LipoButton} from "../../../shared/components/models/lipo-button";

@Component({
  selector: 'du-customer',
  standalone: true,
  imports: [
    LipoOverviewComponent
  ],
  templateUrl: './customer-overview.component.html',
  styleUrl: './customer-overview.component.scss'
})
export class CustomerOverviewComponent implements OnInit {
  customerOverviewModel?: LipoOverviewModel;


  constructor(
    private readonly customerService: CustomerDataService,
    protected router: Router,
    private readonly _progressService: ProgressService,
    ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this.customerService.getCustomersSmall().subscribe({
      next: customers => {
        let customersOverview = customers.map(CustomerServiceMapper.toCustomerOverviewModel);
        let customerCards = customersOverview.map(CustomerOverviewMapper.toLipoCard);
        let customerTable = CustomerOverviewMapper.toLipoTable(customersOverview, this.router);

        this.customerOverviewModel = {
          title: 'customers',
          buttons: LipoButton.build({
            text: "button.customer.add",
            icon: "add",
            onClick: async () => await this.router.navigate([LipoRouteEnum.CUSTOMER, 'new'])
          }),
          cards: customerCards,
          table: customerTable,
        }
      },
      complete: () => this._progressService.stopLoading()
    });
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
