import {LipoFormControlTypeEnum} from "../../enums/lipo-form-control-type.enum";
import {FormControl} from "@angular/forms";
import {startWith} from "rxjs";

/**
 * Represents a set of settings or configuration options for a form control in the Lipo framework.
 * Each setting is defined as a key-value pair, with the key being a string and the value being any type.
 *
 * This interface allows for flexible definition of form control settings, making it easy to extend
 * or customize the configuration as needed.
 */
interface LipoFormControlSettings {
  [key: string]: any;
}

/**
 * Represents a model for a Lipo form control, encapsulating various properties
 * related to form controls including value, key, label, order, control type,
 * style, tooltip, and additional settings.
 *
 * @class
 *
 * @param {object} options - Configuration options for the form control.
 * @param {FormControl} [options.value] - The value of the form control.
 * @param {string} [options.key] - The unique key for the form control.
 * @param {string} [options.label] - The label for the form control.
 * @param {number} [options.order] - The order of the form control in the form.
 * @param {string} [options.style] - The style information for the form control.
 * @param {string} [options.minWidth] - The minWidth information for the form control.
 * @param {string} [options.columns] - The columns information for the form control.
 * @param {string} [options.flex] - The flex information for the form control.
 * @param {string} [options.tooltip] - The tooltip text for the form control.
 * @param {string} [options.placeholder] - The placeholder text for the form control.
 * @param {string} [options.hint] - The hint text for the form control.
 * @param {LipoFormControlSettings} [options.settings] - Additional settings for the form control.
 *
 * @property {FormControl} value - The value associated with the form control.
 * @property {string} key - The unique identifier for the form control.
 * @property {string} label - The label text displayed for the form control.
 * @property {number} order - The sequence order of the form control in the form.
 * @property {LipoFormControlTypeEnum} controlType - The type of the form control.
 * @property {string} style - The CSS style information applied to the form control.
 * @property {string} minWidth - The min width of the form control.
 * @property {string} columns - The columns of the form control.
 * @property {string} flex - Toggle flex of the form control.
 * @property {string} tooltip - The tooltip information shown when hovering over the form control.
 * @property {string} placeholder - The placeholder information shown for the form control.
 * @property {string} hint - The hint information shown for the form control.
 * @property {LipoFormControlSettings} settings - Additional configuration settings for the form control.
 */
export class LipoFormControlModel {
  value: FormControl;
  key: string;
  label: string;
  order: number;
  controlType: LipoFormControlTypeEnum;
  style: string;
  minWidth: string;
  columns: number;
  flex: number;
  tooltip: string;
  placeholder: string;
  hint: string;
  autocomplete: string;
  settings: LipoFormControlSettings;

  constructor(options: {
    value?: FormControl;
    key?: string;
    label?: string;
    order?: number;
    style?: string;
    minWidth?: string;
    columns?: number;
    flex?: boolean;
    tooltip?: string;
    placeholder?: string;
    hint?: string;
    autocomplete?: string;
    settings?: LipoFormControlSettings;
  } = {}) {
    this.value = options.value ?? new FormControl();
    this.key = options.key ?? '';
    this.label = options.label ?? '';
    this.order = options.order ?? 1;
    this.controlType = LipoFormControlTypeEnum.TEXT;
    this.style = options.style ?? '';
    this.minWidth = options.minWidth ?? '';
    this.columns = options.columns ?? 2;
    this.flex = options.flex === false ? 0 : 1;
    this.tooltip = options.tooltip ?? '';
    this.placeholder = options.placeholder ?? '';
    this.hint = options.hint ?? '';
    this.autocomplete = options.autocomplete ?? 'off';
    this.settings = options.settings ?? {};
  }
}

/**
 * A class representing a text box input control for a form.
 *
 * This class extends the LipoFormControlModel and overrides the control type to TEXT.
 *
 * @class
 *
 * @param {Object} [options] - Configuration options for the text box control.
 * @param {Object} [options.settings] - Specific settings for the text box control.
 * @param {number} [options.settings.maxLength=255] - The maximum length of the input text.
 * @param {number} [options.settings.minLength=0] - The minimum length of the input text.
 *
 * @extends LipoFormControlModel
 */
export class LipoFormTextbox extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.TEXT;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      maxLength: options.settings?.maxLength ?? 255,
      minLength: options.settings?.minLength ?? 0
    };
  }
}

/**
 * Represents a password form control in the LipoForm framework.
 * Extends the LipoFormControlModel class and sets the control type to PASSWORD.
 * The visibility of the password can be toggled based on the settings provided.
 *
 * @extends LipoFormControlModel
 *
 * @param {Object} options - Configuration options for the password control.
 * @param {Object} [options.settings] - Additional settings for the password control.
 * @param {boolean} [options.settings.visible=false] - Determines if the password is visible or masked.
 * @param {boolean} [options.settings.showResetIcon=false] - Controls the visibility of the reset icon, which allows users to initiate a password reset process.
 */
export class LipoFormPassword extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.PASSWORD;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      visible: options.settings?.visible ?? false,
      showResetIcon: options.settings?.showResetIcon ?? false,
      onResetIconClick: options.settings?.onResetIconClick ?? (() => Promise.resolve()),
    };
  }
}

/**
 * Represents a form control for a text area, extending the LipoFormControlModel.
 *
 * The controlType is set to TEXTAREA by default.
 *
 * @extends LipoFormControlModel
 *
 * @param {Object} [options] - Configuration options for the text area.
 * @param {Object} [options.settings] - Additional settings for the text area.
 * @param {number} [options.settings.rows=3] - The number of rows in the text area.
 */
export class LipoFormTextArea extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.TEXTAREA;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      rows: options.settings?.rows ?? 3
    };
  }
}

/**
 * LipoFormNumeric is a type of LipoFormControlModel specifically designed to handle numeric input.
 *
 * @class
 * @extends LipoFormControlModel
 *
 * @property {string} controlType - The type of control, which is set to LipoFormControlTypeEnum.NUMERIC.
 * @property {Object} settings - Configuration settings for the numeric control.
 * @property {number} settings.min - Minimum value for the numeric input, default is 0.
 * @property {number} settings.max - Maximum value for the numeric input, default is 100.
 *
 * @param {Object} [options={}] - Initial configuration options.
 * @param {Object} [options.settings] - Settings for numeric input.
 * @param {number} [options.settings.min] - Override for the minimum value.
 * @param {number} [options.settings.max] - Override for the maximum value.
 */
export class LipoFormNumeric extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.NUMERIC;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      min: options.settings?.min ?? null,
      max: options.settings?.max ?? null
    };
  }
}

/**
 * Represents a form control model for phone inputs.
 *
 * Extends the LipoFormControlModel to provide specific functionality
 * for handling phone number input within forms.
 * The control type for this model is set to PHONE.
 *
 * @extends LipoFormControlModel
 */
export class LipoFormPhone extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.PHONE;
}

/**
 * Class representing a Mail Form Control.
 *
 * LipoFormMail extends the LipoFormControlModel class and
 * sets the control type to MAIL, denoted by the
 * LipoFormControlTypeEnum enumeration.
 */
export class LipoFormMail extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.MAIL;
}

/**
 * Represents a form control model for a select dropdown.
 * Extends the LipoFormControlModel to provide specific functionality for select inputs.
 *
 * @class
 * @extends LipoFormControlModel
 *
 * @property {LipoFormControlTypeEnum} controlType Specifies that this form control is a select type.
 * @property {{ key: string, value: any }[]} optionsDefault Holds the default options for the select input.
 * @constructs LipoFormSelect
 * @param {object} [options={}] Configuration options for the select form control.
 */
export class LipoFormSelect extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.SELECT;
  optionsDefault: { key: string, value: any }[] = []

  constructor(options: any = {}) {
    super(options);
    let items = options.settings?.options ?? this.optionsDefault

    this.settings = {
      ...this.settings,
      options: items,
      multiSelect: options.settings?.multiSelect ?? false
    };
    if (this.value.value == null) {
      this.setFirstOnSingle(this.settings);
    }
  }

  private setFirstOnSingle(settings: LipoFormControlSettings) {
    const options = settings['options'] as { key: string, value: any }[];
    if (options?.length === 1) {
      this.value.setValue(options[0].value);
    }
  }
}

/**
 * LipoFormCheckbox is a specialized form control model representing a checkbox input.
 *
 * It extends the LipoFormControlModel and overrides the controlType property to specify
 * that this form control is of type CHECKBOX.
 *
 * This class should be utilized when creating checkbox inputs within a form where instances
 * of LipoFormControlModel are managed. It aids in the type safety and control type distinction
 * in the form control management system.
 */
export class LipoFormCheckbox extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.CHECKBOX;
}

/**
 * LipoFormSlideToggle is a specialized form control model representing a slide-toggle input.
 *
 * It extends the LipoFormControlModel and overrides the controlType property to specify
 * that this form control is of type Slide-toggle.
 */
export class LipoFormSlideToggle extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.SLIDE_TOGGLE;
}

/**
 * Class representing a radio form control model in the Lipo form framework.
 * Extends `LipoFormControlModel` and is specifically used for handling radio button groups.
 *
 * The `LipoFormRadio` class provides a model that contains options for radio buttons,
 * which can be customized via the `options` parameter in the constructor.
 *
 * This class overrides the `controlType` property to specify that the control type is a radio button group.
 * The `optionsDefault` array is used to specify default key-value pairs for the radio buttons.
 *
 * The `constructor` allows initializing the settings of the radio form control,
 * accepting an `options` parameter where custom settings can be provided.
 * If no custom settings for options are provided, it defaults to using `optionsDefault`.
 *
 * @extends LipoFormControlModel
 * @property {LipoFormControlTypeEnum} controlType - The type of the form control, set to `RADIO`.
 * @property {Array<{ key: string, value: any }>} optionsDefault - Default key-value pairs for radio button options.
 * @param {Object} options - The options object for initializing the radio form control settings.
 * @param {Object} [options.settings] - The settings to use for initializing the form control.
 * @param {Array<{ key: string, value: any }>} [options.settings.options] - Custom key-value pairs for radio button options.
 */
export class LipoFormRadio extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.RADIO;
  optionsDefault: { key: string, value: any }[] = []

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      options: options.settings?.options ?? this.optionsDefault,
    };
  }
}

/**
 * LipoFormDate class represents a date input control within a form.
 * It extends the LipoFormControlModel class and sets the controlType
 * property to 'DATE', indicating the type of input control is a date field.
 * This class is used to define and manage date-specific form controls.
 */
export class LipoFormDate extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.DATE;
  constructor(options: any = {}) {
    super(options);

    this.value = options.value || new FormControl(null);
  }
}

/**
 * LipoFormColor class extends LipoFormControlModel to provide a form control
 * specifically for handling color inputs. This class sets the control type to 'COLOR',
 * indicating that it represents a form control for selecting colors.
 *
 * The inherited properties and methods from LipoFormControlModel apply, but the control type
 * is fixed to LipoFormControlTypeEnum.COLOR for this specific form control.
 */
export class LipoFormColor extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.COLOR;
}

/**
 * Represents a form control model for an autocomplete input.
 * Extends the LipoFormControlModel to provide specific functionality for autocomplete inputs.
 *
 * @class
 * @extends LipoFormControlModel
 *
 * @property {LipoFormControlTypeEnum} controlType Specifies that this form control is an autocomplete type.
 * @property {{ key: string, value: any }[]} settings.options Holds the list of all available options for the autocomplete input.
 * @property {{ key: string, value: any }[]} settings.filteredOptions Stores the filtered list of options based on user input.
 * @property {Function} settings.displayFn Formats the display value of the selected option.
 * @constructs LipoFormAutocomplete
 * @param {object} [options={}] Configuration options for the autocomplete form control.
 */
export class LipoFormAutocomplete extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.AUTOCOMPLETE;

  constructor(options: any = {}) {
    super(options);

    const defaultOptions = options.settings?.options ?? [];
    this.settings = {
      ...this.settings,
      options: defaultOptions,
      filteredOptions: [...defaultOptions],
      displayFn: (value: any) => value ? `${value.lastName} ${value.firstName} - ${value.email}` : '',
    };

    this.initializeFilteredOptions();
  }

  private initializeFilteredOptions(): void {
    this.value.valueChanges.pipe(startWith('')).subscribe((value) => {
      const searchText = typeof value === 'string' ? value : value?.key;
      this.settings['filteredOptions'] = this.settings['options'].filter((option: { key: string }) =>
        option.key.toLowerCase().includes(searchText?.toLowerCase() ?? '')
      );
    });
  }
}
