import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoModelInterface} from "../../interfaces/lipo-model.interface";
import {PipeTransform, Type} from "@angular/core";
import {LipoButton} from "./lipo-button";

/**
 * Represents a table model with configurable options for displaying data, columns,
 * and interaction handling such as row selection, button actions, and row click events.
 *
 * @template T The type of data structure that extends LipoModelInterface.
 */
export class LipoTableModel<T extends LipoModelInterface> {
  public settings: {
    header: boolean
    headerAction: boolean
    search: boolean
  };

  constructor(
    public tableDataSource: MatTableDataSource<T>,
    public displayedColumns: LipoTableDisplayedColumns[],
    public selectionModel?: SelectionModel<T>,
    public buttons?: LipoButton[],
    public onRowClick?: (value: LipoModelInterface) => Promise<any>,
    settings?: Partial<{ header: boolean, headerAction: boolean, search: boolean }>
  ) {
    this.settings = {
      header: settings?.header ?? true,
      headerAction: settings?.headerAction ?? true,
      search: settings?.search ?? true,
    };
  }
}


export class LipoTableDisplayedColumns {
  public HeaderCellName?: string;
  public PropertyName!: string;
  public HeaderPipes: PipeTransform[] = [];
  public BodyPipes: PipeTransform[] = [];
  public Component: Type<any> | undefined = undefined;

  /**
   * Builds an array of `LipoTableDisplayedColumns` instances based on the provided column definitions.
   *
   * @param {...{HeaderCellName: string, PropertyName: string, HeaderPipes?: PipeTransform[], BodyPipes?: PipeTransform[], Component?: Type<any>}} columns
   * An array of objects representing column definitions. Each object should contain the following properties:
   * - `HeaderCellName`: A string representing the name of the header cell.
   * - `PropertyName`: A string representing the property name associated with the column.
   * - `HeaderPipes` (optional): An array of `PipeTransform` instances used as pipes for the header cell.
   * - `BodyPipes` (optional): An array of `PipeTransform` instances used as pipes for the body cells.
   * - `Component` (optional): A custom component of type `Type<any>` associated with the column. When using Components you can pass inputs as value. PropertyName: 'inputs'
   *
   * @return {LipoTableDisplayedColumns[]}
   * An array of `LipoTableDisplayedColumns` instances created based on the input column definitions.
   */
  static build(...columns: {HeaderCellName: string, PropertyName: string, HeaderPipes?: PipeTransform[], BodyPipes?: PipeTransform[], Component?: Type<any>}[]): LipoTableDisplayedColumns[] {
    return columns.map(item => {
      const instance = new LipoTableDisplayedColumns();
      instance.HeaderCellName = item.HeaderCellName;
      instance.PropertyName = item.PropertyName;
      instance.HeaderPipes = item.HeaderPipes || [];
      instance.BodyPipes = item.BodyPipes || [];
      instance.Component = item.Component || undefined;
      return instance;
    });
  }
}
