import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {Address} from "../../../shared/models/lipo-address.model";
import {SystemDetailModel} from "../../../system/components/models/system-detail.model";
import {PartnerDetailModel} from "../../../partner/components/models/partner-detail.model";
import {UserDetailModel} from "../../../user/components/models/user-detail.model";

export class CustomerDetailModel implements LipoModelInterface {
  constructor(
    public id: number | null = null,
    public name: string = '',
    public mail: string = '',
    public phone: string = '',
    public partner: PartnerDetailModel | number | null = null,
    public addresses: Address[] = [],
    public systems: SystemDetailModel[] = [],
    public users: UserDetailModel[] = [],
    public logoUrl: string | null = null,
    public createUsersInKeyCloak: boolean = false,
    public sendMailToCustomer: boolean = true,
    public sendEmailToPartner: boolean = true
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}

export class CustomerOverviewModel implements LipoModelInterface {
  constructor(
    public id: number | null = null,
    public name: string = '',
    public mail: string = '',
    public phone: string = '',
    public partner: number,
    public addresses: number,
    public systems: number,
    public users: number,
    public logoUrl: string | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}


