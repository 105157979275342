import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TenantDetailModel} from "../models/tenant-detail.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {TenantDetailMapper} from "../../mappers/tenant-detail.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {TenantServiceMapper} from "../../mappers/tenant-service.mapper";
import {TenantDataService} from "../../services/tenant-data.service";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {SystemDataService} from "../../../system/services/system-data.service";
import {TranslateService} from "@ngx-translate/core";
import {LicenseWizardModel} from "../../../licence/components/models/license-wizard.model";
import {LicenseWizardDialogComponent} from "../../../licence/components/wizard/license-wizard-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {v4 as uuidv4} from "uuid";
import {getTenantDetailFormFields} from "./tenant-detail.form-fields";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {
  getAppLicenseTable,
  getAppSettingsTable,
  getTenantTokenTable,
  getTenantUserMappingsTable
} from "./tenant-detail.table";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {DatePipe} from "@angular/common";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {TenantUserMappingDetailModel} from "../models/tenant-user-mapping-detail.model";
import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";
import {ProgressService} from "../../../shared/services/progress.service";
import {TenantDetailDialogComponent} from "../tenant-detail-dialog/tenant-detail-dialog.component";
import {KeycloakRoleEnum} from "../../../shared/enums/keycloak-role.enum";
import {KeycloakService} from "keycloak-angular";
import {
  LipoResetPasswordDialogComponent
} from "../../../shared/components/lipo-reset-password-dialog/lipo-reset-password-dialog.component";
import {LipoFormControlModel} from "../../../shared/components/models/lipo-form-control.model";
import {
  LipoConfirmationDialogComponent
} from "../../../shared/components/lipo-confirmation-dialog/lipo-confirmation-dialog.component";
import {
  TenantItInstructionsDialogComponent
} from "../tenant-it-instructions-dialog/tenant-it-instructions-dialog.component";
import {TenantItInstructionsService} from "../../services/tenant-it-instructions.service";
import {ProductDataService} from "../../../product/services/product-data.service";
import {AppLicenseDetailMapper} from "../../../licence/mappers/app-license-detail.mapper";

@Component({
  selector: 'du-tenant-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './tenant-detail.component.html',
  styleUrl: './tenant-detail.component.scss'
})
export class TenantDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  backupTenant?: TenantDetailModel;
  systemId: number | null = null;
  tenantId: number | null = null;
  systemName: string | null = null;
  isSaving: boolean = false
  private readonly encryptPrefix = 'encrypt_';
  menuButtons: LipoButton[] = [];

  private readonly _appSettingsTableUuid = uuidv4();
  private readonly _userMappingsTableUuid = uuidv4();
  private readonly _tokenTableUuid = uuidv4();
  private readonly _appLicensesTableUuid = uuidv4();

  private routeData: Data | undefined;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _tenantService: TenantDataService,
    private _systemService: SystemDataService,
    private _translationService: TranslateService,
    private _datePipe: DatePipe,
    private _progressService: ProgressService,
    private _keycloakService: KeycloakService,
    private dialog: MatDialog,
    private tenantConfigService: TenantItInstructionsService,
    private readonly _productDataService: ProductDataService,
  ) {
    _progressService.stopLoading()
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.routeData = data
        this.handleRouteData(data);
        this.menuButtons = this.getMenuButtons();
      }
    });
  }

  private handleRouteData(data: Data): void {
    const routesDataModel = data as LipoRoutesDataModel;
    this.handleParams();
    this.handleTenantData(routesDataModel);
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.systemId = this.idToNumber(params.get('systemId'));
      this.tenantId = this.idToNumber(params.get('tenantId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handleTenantData(routesDataModel: LipoRoutesDataModel): void {
    if (routesDataModel.isCreateItem) {
      this.createEmptyTenant();
    } else {
      if (this.systemId !== null) {
        this._systemService.getSystem(this.systemId).subscribe({
          next: value => {
            let tenantServiceModel = value.tenants.find(tenant => tenant.id === this.tenantId)
            if (tenantServiceModel !== undefined) {
              let tenant = TenantServiceMapper.toTenantDetailModel(tenantServiceModel);
              this.backupTenant = tenant;
              this.systemName = value.name;
              this.setTenant(tenant);
            } else {
              this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId]).then(() => this._snackBar.Warning(this._translationService.instant('snackbar.notfound')))
            }
          }
        });
      }
    }
  }

  setTenant(tenant: TenantDetailModel): void {
    this.createDetail(tenant);
    const sapPasswordControl = this.detailModel?.form?.formGroup.get('sap')?.get('sapPassword');
    if (sapPasswordControl && sapPasswordControl.value && !sapPasswordControl.value.startsWith(this.encryptPrefix)) {
      sapPasswordControl.setValue(`${this.encryptPrefix}${sapPasswordControl.value}`);
      sapPasswordControl.disable();
    }
  }

  createEmptyTenant(): void {
    let emptyTenantModel = new TenantDetailModel();
    this.createDetail(emptyTenantModel);
  }

  createDetail(tenantModel: TenantDetailModel): void {
    const isAdmin = this._keycloakService.isUserInRole(KeycloakRoleEnum.ADMIN);

    getTenantDetailFormFields(tenantModel, isAdmin, this.openResetPasswordDialog.bind(this), this.onCopyProxyLinkClicked.bind(this)).subscribe({
      next: async baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        let tabs = await this.getTabs(tenantModel)

        this.detailModel = TenantDetailMapper.toLipoDetailModel(tenantModel, lipoFormModel, tabs)
      },
      complete: () => this._progressService.stopLoading()
    });
  }

  async getTabs(model: TenantDetailModel): Promise<LipoTab[]> {
    const appLicensesPromises = model.appLicenses
      .map(a => AppLicenseDetailMapper.toAppLicenseTableModel(a, this._productDataService));

    const appLicenses = await Promise.all(appLicensesPromises);

    return LipoTab.build(
      {
        title: 'licenses',
        components: [
          {
            uuid: this._appLicensesTableUuid,
            component: getAppLicenseTable(appLicenses, this._datePipe, this.getAppLicenseTableButtons())
          }
        ]
      },
      {
        title: 'appSettings',
        components: [
          {
            uuid: this._appSettingsTableUuid,
            component: getAppSettingsTable(model.appSettings, this.getAppSettingsTableButtons(), this.onAppSettingsTableRowClick.bind(this)),
          }
        ]
      },
      {
        title: 'userMappings',
        components: [
          {
            uuid: this._userMappingsTableUuid,
            component: getTenantUserMappingsTable(model.tenantUserMappings, this.getUserMappingsTableButtons(), this.onTenantUserMappingTableRowClick.bind(this))
          }
        ]
      },
      {
        title: 'apiTokens',
        components: [
          {
            uuid: this._tokenTableUuid,
            component: getTenantTokenTable(model.tokens, this._datePipe, this.getTokenTableButtons())
          }
        ]
      },
    );
  }

  async onAppSettingsTableRowClick(value: LipoModelInterface) {
    let appSettingsId = value.getId();

    let appSettingsTable = this.detailModel?.findComponent<LipoTableModel<TenantAppSettingsDetailModel>>(this._appSettingsTableUuid)
    let appSetting = appSettingsTable?.tableDataSource.data.find(data => data.getId() === appSettingsId);

    if (appSetting) {
      await this._router.navigate([
        LipoRouteEnum.SYSTEM,
        this.systemId,
        LipoRouteEnum.TENANT,
        this.tenantId,
        LipoRouteEnum.APP_SETTINGS,
        appSettingsId
      ]);
    }
  }

  async onTenantUserMappingTableRowClick(value: LipoModelInterface) {
    let tenantUserMappingId = value.getId();

    let tenantList = this.detailModel?.findComponent<LipoTableModel<TenantUserMappingDetailModel>>(this._userMappingsTableUuid)
    let tenantUserMapping = tenantList?.tableDataSource.data.find(data => data.getId() === tenantUserMappingId);

    if (tenantUserMapping) {
      await this._router.navigate([
        LipoRouteEnum.SYSTEM,
        this.systemId,
        LipoRouteEnum.TENANT,
        this.tenantId,
        LipoRouteEnum.USER_MAPPING,
        tenantUserMappingId
      ]);
    }
  }

  getAppLicenseTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.license.activate",
      icon: "license",
      onClick: () => {
        this.openLicenseWizard()
        return Promise.resolve()
      },
    });
  }

  openLicenseWizard(): void {
    this.dialog.open(LicenseWizardDialogComponent, {
      data: new LicenseWizardModel({
        systemId: this.systemId,
        tenantId: this.tenantId,
      }),
    }).afterClosed().subscribe({
      next: result => {
        if(result) {
          this.handleRouteData(this.routeData!)
        }
      }
    })
  }

  getAppSettingsTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.appSettings.add",
      icon: "add",
      onClick: async () => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId, LipoRouteEnum.APP_SETTINGS, 'new']),
    });
  }

  getUserMappingsTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.userMapping.add",
      icon: "add",
      onClick: async () => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId, LipoRouteEnum.USER_MAPPING, 'new']),
    });
  }

  getTokenTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.token.generate",
      icon: "token",
      onClick: async () => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId, LipoRouteEnum.TOKEN, 'new']),
    });
  }

  onDeleteClicked(): void {
    if (!this.tenantId || !this.systemId) return;

    this._tenantService.deleteTenant(this.tenantId, this.systemId).subscribe({
      next: (systemModel) => {
        if (systemModel) {
          this._router.navigate([
            LipoRouteEnum.SYSTEM,
            systemModel.id,
          ]).then(() => this._snackBar.Deleted());
        }
      }
    });
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form?.formGroup || !this.systemId) return;

    const activeControl = detailModel.form.formGroup.get('checkBoxes')?.get('active');
    const sapUserControl = detailModel.form.formGroup.get('sap')?.get('sapUser');
    const sapPasswordControl = detailModel.form.formGroup.get('sap')?.get('sapPassword');
    const isCurrentlyActive = this.backupTenant?.active;

    if (activeControl?.value === false && isCurrentlyActive) {
      this.dialog.open(LipoConfirmationDialogComponent, {
        data: {
          title: this._translationService.instant('dialog.deactivateTenant.title'),
          content: this._translationService.instant('dialog.deactivateTenant.message'),
          confirmButtonText: this._translationService.instant('button.confirm'),
          cancelButtonText: this._translationService.instant('button.cancel'),
        }
      }).afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.backupTenant!.active = false;
          this.saveTenant(detailModel);
        } else {
          activeControl.setValue(true);
        }
      });
      return;
    }

    if (activeControl?.value === true) {
      if (!sapUserControl?.value || !sapPasswordControl?.value) {
        this.openSapDialog(sapUserControl?.value, sapPasswordControl?.value);
        return;
      }
    }

    this.saveTenant(detailModel);
  }

  private saveTenant(detailModel: LipoDetailModel): void {
    const serviceTenantModel = LipoFormMapper.toTenantSaveModel(detailModel.form?.formGroup!, this.backupTenant);
    const id = this.tenantId;

    this.isSaving = true
    if (id !== null && id > 0) {
      if (this.systemId != null) {
        this._tenantService.updateTenant(serviceTenantModel, this.systemId).subscribe({
          next: tenant => {
            this.setTenant(TenantServiceMapper.toTenantDetailModel(tenant))
            this._snackBar.Saved();
          },
          error: () => this.isSaving = false,
          complete: () => this.isSaving = false
        });
      }
    } else if (this.systemId != null) {
      this._tenantService.createTenant(serviceTenantModel, this.systemId).subscribe({
        next: tenant => {
          const routes = [LipoRouteEnum.SYSTEM, this.systemId];
          if (tenant.id) {
            routes.push(LipoRouteEnum.TENANT, tenant.id);
          }
          this._router.navigate(routes).then(() => this._snackBar.Saved());
        },
        error: () => this.isSaving = false,
        complete: () => this.isSaving = false
      });
    }
  }

  onLicenseWizardClicked(): void {
    this.dialog.open(LicenseWizardDialogComponent, {
      data: new LicenseWizardModel({
        systemId: this.systemId,
        tenantId: this.tenantId
      }),
    }).afterClosed().subscribe({
      next: result => {
        if(result) {
          this.handleRouteData(this.routeData!)
        }
      }
    });
  }

  onGenerateProxyNameClicked(): void {
    if (this.systemName && this.backupTenant && this.tenantId !== null) {
      const systemName = this.systemName.trim();
      const tenantName = this.backupTenant.sapCompanyDB.trim() || 'tenant';
      const proxyName = `${systemName}-${tenantName}-${this.tenantId}`.trim();

      if (!this.detailModel || !this.detailModel.form?.formGroup) {
        return;
      }

      const proxyNameField = this.detailModel.form.formGroup.get('connection')?.get('proxyName')

      if (proxyNameField) {
        proxyNameField.setValue(proxyName);
        proxyNameField.markAsDirty();
        this._snackBar.Success(this._translationService.instant('proxyName.generated',{ proxyName }));
        if (proxyNameField.value) {
          return;
        }
      }
    }
  }

  onITInstructionsClicked(): void {
    const portControl = this.detailModel?.form?.formGroup?.get('connection')?.get('port');
    const externalPortControl = this.detailModel?.form?.formGroup?.get('connection')?.get('externalPort');
    const portValue = portControl?.value;
    const externalPortValue = externalPortControl?.value;
    const ipWhitelist = this.tenantConfigService.getTenantITInstructionsIpWhitelist();

    this.dialog.open(TenantItInstructionsDialogComponent, {
      data: {
        sapServerName: this.systemName,
        systemId: this.systemId,
        tenantId: this.tenantId,
        ipWhitelist,
        internalPort: portValue,
        externalPort: externalPortValue,
        tenants: [],
        showTenantNames: false,
      },
    });
  }



  private openSapDialog(sapUser: string | null, sapPassword: string | null): void {
    const dialogRef = this.dialog.open(TenantDetailDialogComponent, {
      data: {
        sapUser: sapUser || '',
        sapPassword: sapPassword || ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const sapUserControl = this.detailModel?.form?.formGroup?.get('sap')?.get('sapUser');
        const sapPasswordControl = this.detailModel?.form?.formGroup?.get('sap')?.get('sapPassword');

        if (sapUserControl) {
          sapUserControl.setValue(result.sapUser);
        }

        if (sapPasswordControl && result.sapPassword) {
          const prefixedPassword = result.sapPassword.startsWith(this.encryptPrefix)
            ? result.sapPassword
            : `${this.encryptPrefix}${result.sapPassword}`;

          sapPasswordControl.setValue(prefixedPassword);
          sapPasswordControl.disable();
        }
        this.saveTenant(this.detailModel!);
      }
    });
  }

  async openResetPasswordDialog(controlModel: LipoFormControlModel): Promise<void> {
    const originalPassword = controlModel.value.getRawValue();

    const dialogRef = this.dialog.open(LipoResetPasswordDialogComponent, {
      disableClose: true,
      data: { currentPassword: originalPassword }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        controlModel.value.setValue(result);
        this.onSaveClick(this.detailModel!);
      } else {
        controlModel.value.setValue(originalPassword);
      }
    });
  }

  onCheckPortStatusClicked(): void {
    const endpointControl = this.detailModel?.form?.formGroup.get('connection')?.get('endpointUrl');
    const portControl = this.detailModel?.form?.formGroup.get('connection')?.get('port');

    if (!endpointControl?.value || !portControl?.value) {
      this._snackBar.Warning(this._translationService.instant('snackbar.endpointPortMissing'));
      return;
    }

    if (this.systemId && this.tenantId) {
      this._tenantService.pingTenant(this.systemId, this.tenantId).subscribe({
        next: (isReachable) => {
          const checkInboundControl = this.detailModel?.form?.formGroup.get('checkBoxes')?.get('checkInbound');
          if (checkInboundControl) {
            checkInboundControl.setValue(isReachable);
            if (isReachable) {
              this._snackBar.Success(this._translationService.instant('snackbar.portCheckSuccessful'));
              setTimeout(() => {
                this.saveTenant(this.detailModel!);
              }, 1000);
            } else {
              this._snackBar.Error(this._translationService.instant('snackbar.portCheckUnreachable'));
            }
          }
        },
        error: () => {
          const checkInboundControl = this.detailModel?.form?.formGroup.get('checkBoxes')?.get('checkInbound');
          if (checkInboundControl) {
            checkInboundControl.setValue(false);
          }
          this._snackBar.Error(this._translationService.instant('snackbar.portCheckFailed'));
        }
      });
    }
  }

  async onCopyProxyLinkClicked(controlModel?: LipoFormControlModel): Promise<void> {
    const proxyName = controlModel?.value.getRawValue() || this.backupTenant?.proxyName;
    if (!proxyName) {
      this._snackBar.Warning(
        this._translationService.instant('snackbar.proxyNameMissing')
      );
      return;
    }
    const proxyLink = `${this._tenantService['api']}/proxy/${proxyName}`;
    navigator.clipboard.writeText(proxyLink).then(
      () =>
        this._snackBar.Success(
          this._translationService.instant('snackbar.proxyLinkCopied', { proxyLink }), 6000
        ),
      () =>
        this._snackBar.Error(
          this._translationService.instant('snackbar.copyFailed')
        )
    );
  }

  getMenuButtons(): LipoButton[] {
    return LipoButton.build(
      {
        text: "button.proxyName.generate",
        icon: "code",
        onClick: async () => this.onGenerateProxyNameClicked(),
      },
      {
        text: "button.license.activate",
        icon: "license",
        onClick: async () => this.onLicenseWizardClicked(),
      },
      {
        text: "button.it.instructions",
        icon: "info",
        onClick: async () => this.onITInstructionsClicked(),
      },
      {
        text: "button.checkPorts",
          icon: "cycle",
        onClick: async () => this.onCheckPortStatusClicked(),
      },
      {
        text: "button.copyProxyLink",
        icon: "content_copy",
        onClick: async () => this.onCopyProxyLinkClicked(),
      }
    );
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
