type YesNo = "Y" | "N" | undefined;

export class StoreLinkSettingsServiceModel {
  JSONataHost: string = ''
  JSONataUser: string = ''
  JSONataPassword: string = ''
  JsonPath: string = ''
  DefaultPriceList: string | undefined
  DefaultWarehouse: string | undefined
  PrePaymentCreateReservedInvoice: YesNo
  PrePaymentCreateDownPayment: YesNo
  PrePaymentCreateIncomingPayment: YesNo
  //ShopwareSettingsModel
  ShopwareHost: string | undefined
  ShopwareTokenUrl: string | undefined
  ShopwareTokenUser: string | undefined
  ShopwareTokenPassword: string | undefined
  ShopwareSyncApiUrl: string | undefined
  CreateContainerProductForVariantGroup: YesNo
  IsB2B: YesNo
  //ShopifySettingsModel
  ShopifyHost: string | undefined
  ShopifyToken: string | undefined
  ShopifyStockBatchSize: number | undefined
  CreateBusinessPartnerFromSalesOrder: YesNo
}

export class StoreLinkSettingsModel {
  constructor(
    public JSONataHost: string = '',
    public JSONataUser: string = '',
    public JSONataPassword: string = '',
    public JsonPath: string = '',
    public DefaultPriceList: string | null = null,
    public DefaultWarehouse: string | null = null,
    public PrePaymentCreateReservedInvoice: boolean | null = null,
    public PrePaymentCreateDownPayment: boolean | null = null,
    public PrePaymentCreateIncomingPayment: boolean | null = null,
  ) {}
}

export class ShopwareSettingsModel {
  constructor(
    public ShopwareHost: string = '',
    public ShopwareTokenUrl: string = '',
    public ShopwareTokenUser: string = '',
    public ShopwareTokenPassword: string = '',
    public ShopwareSyncApiUrl: string = '',
    public CreateContainerProductForVariantGroup: boolean | null = null,
    public IsB2B: boolean | null = null,
  ) {}
}

export class ShopifySettingsModel {
  constructor(
    public ShopifyHost: string = '',
    public ShopifyToken: string = '',
    public ShopifyStockBatchSize: number = 100,
    public CreateBusinessPartnerFromSalesOrder: boolean | null = null,
  ) {}
}
