import {FormControl, FormGroup} from "@angular/forms";
import {LipoFormControlModel} from "../components/models/lipo-form-control.model";
import {LipoFormGroupModel} from "../components/models/lipo-form-group.model";
import {LipoButton} from "../components/models/lipo-button";

export class LipoFormModel {
  formGroup: FormGroup;

  constructor(
    public baseForms: LipoFormGroupModel[],
    public columns: number = 1,
    public style: string = '',
  ) {
    this.formGroup = this.toFormGroup(baseForms);
  }

  private toFormGroup(baseForms: (LipoFormControlModel | LipoFormGroupModel)[]): FormGroup {
    const group: any = {};
    baseForms.sort((a, b) => a.order - b.order);
    baseForms.forEach(baseForm => {
      if (baseForm instanceof LipoFormGroupModel) {
        group[baseForm.key] = this.toFormGroup(baseForm.controls);
      } else {
        group[baseForm.key] = baseForm.value;
      }
    });

    return new FormGroup(group);
  }

  addGroup(formGroup: LipoFormGroupModel): void {
    if (this.formGroup.contains(formGroup.key)) {
      return;
    }
    this.formGroup.addControl(formGroup.key, this.toFormGroup(formGroup.controls));
    this.baseForms.push(formGroup);
  }

  removeGroup(groupKey: string | null): void {
    if(groupKey === null) return;
    this.formGroup.removeControl(groupKey)
    this.baseForms = this.baseForms.filter(baseForm => baseForm.key !== groupKey)
  }

  addControl(formGroupKey: string, formControl: LipoFormControlModel): void {
    const targetGroup = this.formGroup.get(formGroupKey) as FormGroup;
    const targetBaseForm = this.baseForms.find(baseForm => baseForm.key === formGroupKey);
    if (targetGroup && targetBaseForm) {
      targetBaseForm.controls.push(formControl);
      targetGroup.addControl(formControl.key, new FormControl(formControl.value));
    }
  }

  addButtonsToGroup(groupKey: string, buttons: LipoButton[]): void {
    const targetGroup = this.baseForms.find(baseForm => baseForm.key === groupKey);
    if (targetGroup) {
      targetGroup.buttons.push(...buttons);
    } else {
      console.warn(`Group with key ${groupKey} not found.`);
    }
  }
}
