/**
 * Represents the settings for mobile logistics, including company policies,
 * subscription information, and navigation bar customization options.
 * @property {string} Subscriptions - must be '|' seperated
 */
export class MobileLogisticsSettingsModel {
  constructor(
    public CompanyPolicies: CompanyPoliciesModel = new CompanyPoliciesModel(),
    public Subscriptions: string = '',
    public NavBarColor: string = '',
    public NavBarTextAccentColor: string = '',
    public NavBarTextColor: string = '',
  ) {}
}

export class CompanyPoliciesModel {
  constructor(
    public HideQuantities: boolean = false,
    public PackagingItemAllocation: boolean = false,
    public ItemScanningCountingEnabled: boolean = false,
    public ItemScanningPicklistEnabled: boolean = false,
    public ItemScanningGoodsReceiptEnabled: boolean = false,
    public BulkScanEnabled: boolean = false,
  ) {}
}
