import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import moment from "moment/moment";

export class AppLicenseDetailModel implements LipoModelInterface {
  constructor(
    public purchaseDate: moment.Moment,
    public expiryDate: moment.Moment,
    public customerId: number | null = null,
    public tenantId: number | null = null,
    public productId: number | null = null,
    public productPriceId: number | null = null,
    public isActive: boolean | null = null,
    public isActiveText: 'active' | 'idle' = "idle",
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}

export class AppLicenseTableModel  implements LipoModelInterface {
  constructor(
    public purchaseDate: moment.Moment,
    public expiryDate: moment.Moment,
    public productName: string | null = null,
    public productPriceName: string | null = null,
    public productPrice: number | null = null,
    public isActive: boolean | null = null,
    public isActiveText: 'active' | 'idle' = "idle",
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}
