import {Component, inject, Input} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {LipoCardModel} from "../../models/lipo-card.model";
import {NgClass, NgComponentOutlet, NgOptimizedImage} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import {Router} from "@angular/router";
import {LipoRouteEnum} from "../../enums/lipo-route.enum";
import {TranslateModule} from "@ngx-translate/core";
import {ConfigurationService} from "../../services/configuration.service";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {MatRipple} from "@angular/material/core";
import {LipoAmountBadgesComponent} from "../lipo-amount-badges/lipo-amount-badges.component";

@Component({
  selector: 'du-lipo-card',
  standalone: true,
  imports: [
    MatCardModule,
    MatDividerModule,
    NgOptimizedImage,
    TranslateModule,
    MatGridListModule,
    MatIconModule,
    NgComponentOutlet,
    MatRipple,
    NgClass,
    LipoAmountBadgesComponent,
  ],
  templateUrl: './lipo-card.component.html',
  styleUrl: './lipo-card.component.scss'
})
export class LipoCardComponent{
  @Input() disabled: boolean = false;
  @Input() content!: LipoCardModel

  defaultLogo: string;

  constructor(
    private _router: Router,
    ) {
    this.defaultLogo = inject(ConfigurationService).configuration.app.defaultLogo;
  }

  async navigateToRoute() {
    if (this.content.route) {
      await this._router.navigate(this.content.route.commands, this.content.route.extras);
    }
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
