import {Type} from "@angular/core";
import {NavigationExtras, UrlTree} from "@angular/router";

/**
 * Interface representing the options for a LipoCardModel.
 *
 * @property {number | null} id - The unique identifier for the card. Can be null.
 * @property {{commands: any[], extras?: NavigationExtras} | undefined} route - The route configuration for navigation, consisting of commands and optional extras.
 * @property {string} title - The title of the card.
 * @property {string | null} [subtitle] - The optional subtitle of the card. Can be null.
 * @property {{component: Type<any>, inputs: Record<string, unknown>} | null} [body] - The optional body component of the card, including the component type and its inputs. Can be null.
 * @property {string | null} [logoUrl] - The optional URL of the logo to be displayed on the card. Can be null.
 */
interface LipoCardModelOptions {
  id: number | null;
  route: {commands: any[], extras?: NavigationExtras} | undefined;
  title?: string | null;
  body?: {
    component: Type<any>,
    inputs: Record<string, unknown>
  } | null;
  logoUrl?: string | null;
  badges?: {amount: number, label: string, routerLink: any[] | string | UrlTree | null | undefined}[]
}

export class LipoCardModel {
  public id: number | null;
  public route: {commands: any[], extras?: NavigationExtras} | undefined;
  public title: string | null;
  public body: {
    component: Type<any>,
    inputs: { [key: string]: any }
  } | null;
  public logoUrl: string | null;
  public badges: {amount: number, label: string, routerLink: any[] | string | UrlTree | null | undefined}[]
  [key: string]: any;

  constructor(options: LipoCardModelOptions) {
    this.id = options.id;
    this.route = options.route;
    this.title = options.title ?? null;
    this.body = options.body ?? null;
    this.logoUrl = options.logoUrl ?? null;
    this.badges = options.badges ?? [];
  }
}
