<div class="container">
  <mat-card>
    <form [formGroup]="realmForm" (ngSubmit)="onSubmit()">
      <mat-card-header>
        <div class="header-container">
          <div class="logo-container">
            <img ngSrc="assets/images/logo.png" alt="logo" fill priority/>
          </div>
          <div class="title">DATA UNIT AG</div>
          <h1 class="subtitle">{{ 'form.enterMail' | translate }}</h1>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="dense-6 card-body-container">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" color="accent">
            <mat-label>{{ 'mail' | translate }}</mat-label>
            <input id="email-realm-chooser" matInput formControlName="email" autocomplete="email">
            <mat-error *ngIf="realmForm.get('email')?.invalid && realmForm.get('email')?.dirty">
              {{ 'error.EmailInvalid' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="submit-container">
          <button mat-flat-button type="submit" [disabled]="realmForm.invalid">{{ 'button.submit' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
    <div class="mat-card-footer">
      <div class="footer-container">
        {{ 'register.text' | translate }} <a href="https://www.dataunit.ch/license-registration/"
                                             target="_blank">{{ 'register.link' | translate }}</a>
      </div>
    </div>
  </mat-card>
</div>
