import {Injectable} from '@angular/core';
import {catchError, forkJoin, map, Observable, of} from "rxjs";
import {CustomerOverviewServiceModel, CustomerServiceModel,} from "./models/customer-service.model";
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {UserServiceModel, UserServiceSaveModel} from "../../user/services/models/user-service.model";

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService {

  private readonly customerEndpoint: string;
  private readonly userEndpoint: string;
  private readonly api:string;

  constructor(
    private _http: HttpClient,
    _config: ConfigurationService
  ) {
    let config = _config.configuration;

    this.customerEndpoint = config.api.endpoints.customer;
    this.userEndpoint = config.api.endpoints.user;
    this.api = config.api.backendUrl;
  }

  public getCustomers(): Observable<CustomerServiceModel[]> {
    return this._http.get<CustomerServiceModel[]>(`${this.api}/${this.customerEndpoint}/full`);
  }

  public getCustomersSmall(): Observable<CustomerOverviewServiceModel[]> {
    return this._http.get<CustomerOverviewServiceModel[]>(`${this.api}/${this.customerEndpoint}`);
  }

  public getCustomer(id: number): Observable<CustomerServiceModel> {
    return this._http.get<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}/${id}`);
  }

  public createCustomer(customer: CustomerServiceModel): Observable<CustomerServiceModel> {
    return this._http.post<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}`, customer);
  }

  public updateCustomer(customer: CustomerServiceModel): Observable<CustomerServiceModel> {
    return this._http.put<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}/${customer.id}`, customer);
  }

  public deleteCustomer(id: number): Observable<boolean> {
    return this._http.delete<boolean>(`${this.api}/${this.customerEndpoint}/${id}`);
  }

  public getCustomerUser(customerId: number, userId: number): Observable<UserServiceModel> {
    return this._http.get<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}/${customerId}`).pipe(
      map((customerServiceModel: CustomerServiceModel) => this.getUserById(customerServiceModel, userId))
    );
  }

  public createCustomerUser(customerId: number, user: UserServiceSaveModel): Observable<UserServiceModel> {
    return this._http.post<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}/${customerId}/${this.userEndpoint}`, user).pipe(
      map((customerServiceModel: CustomerServiceModel) => this.getUserByMail(customerServiceModel, user.email))
    );
  }

  public updateCustomerUser(customerId: number, user: UserServiceModel): Observable<UserServiceModel> {
    return this._http.put<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}/${customerId}/${this.userEndpoint}/${user.id}`, user).pipe(
      map((customerServiceModel: CustomerServiceModel) => this.getUserById(customerServiceModel, user.id))
    );
  }

  public deleteCustomerUser(customerId: number, userId: number): Observable<CustomerServiceModel> {
    return this._http.delete<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}/${customerId}/${this.userEndpoint}/${userId}`);
  }

  public deleteCustomerUsers(customerId: number, idList: (number | null)[]): Observable<number[]> {
    const tasks = idList.map(id => {
      if (id === null) return;
      return this.deleteCustomerUser(customerId, id).pipe(
        catchError((error) => {
          console.log(error);
          return of(id);
        })
      );
    });

    return forkJoin(tasks).pipe(
      map(results => {
        return results.filter(result => typeof result === 'number');
      })
    );
  }

  private getUserById(customerServiceModel: CustomerServiceModel, userId: number | null): UserServiceModel {
    const userModel = customerServiceModel.users.find(user => user.id === userId);
    return userModel || {} as UserServiceModel;
  }

  private getUserByMail(customerServiceModel: CustomerServiceModel, userMail: string | null): UserServiceModel {
    const userModel = customerServiceModel.users.find(user => user.email === userMail);
    return userModel || {} as UserServiceModel;
  }

  public syncUserToKeyCloak(customerId: number): Observable<CustomerServiceModel> {
    return this._http.post<CustomerServiceModel>(`${this.api}/${this.customerEndpoint}/${customerId}/sync`, null);
  }
}
