/**
 * Converts the properties of an object into a dictionary where all values are strings.
 * If a property value is an object, it is stringified using JSON.stringify.
 *
 * @param {any} obj - The input object to be converted into a string dictionary.
 * @return {{ [key: string]: string }} A dictionary with string keys and string values.
 */
export function toStrDict(obj: any): { [key: string]: string } {
  const result: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      result[String(key)] = JSON.stringify(value);
    } else if (typeof value === 'object' && value !== null) {
      result[String(key)] = JSON.stringify(toStrDict(value));
    } else {
      result[String(key)] = String(value);
    }
  }

  return result;
}

/**
 * Converts an object with string values into an object with parsed values.
 * If a string value can be parsed as JSON, it will be converted to its parsed form (e.g., object, array, number).
 * If parsing fails, the original string value is retained.
 *
 * @param {Object.<string, string>} obj - An object where the keys are strings and the values are strings to be parsed.
 * @return {Object} - An object where the values are parsed (if JSON parsable) or left as their original string values.
 */
export function fromStrDict<T>(obj: { [key: string]: string }): T {
  const result: any = {};

  for (const [key, value] of Object.entries(obj)) {
    try {
      result[key] = JSON.parse(value);
    } catch (e) {
      result[key] = value;
    }
  }

  return result;
}
