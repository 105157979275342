import {Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {LipoRouteEnum} from "../../enums/lipo-route.enum";
import {RouterLink, UrlTree} from "@angular/router";
import {NgClass} from "@angular/common";

@Component({
  selector: 'du-lipo-amount-badges',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    NgClass
  ],
  templateUrl: './lipo-amount-badges.component.html',
  styleUrl: './lipo-amount-badges.component.scss'
})
export class LipoAmountBadgesComponent {
  @Input() badges: {amount: number, label: string, routerLink: any[] | string | UrlTree | null | undefined}[] = []

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
