import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {SystemOverviewModel} from "../services/models/system-overview.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../shared/components/models/lipo-table.model";
import {MatTableDataSource} from "@angular/material/table";
import {LipoModelInterface} from "../../shared/interfaces/lipo-model.interface";
import {LipoTableImageComponent} from "../../shared/components/lipo-table-image/lipo-table-image.component";
import {Router} from "@angular/router";
import {LipoAmountBadgesComponent} from "../../shared/components/lipo-amount-badges/lipo-amount-badges.component";
import {
  SystemOverviewCardBodyComponent
} from "../components/system-overview-card-body/system-overview-card-body.component";
import {SystemHeartbeatStatusComponent} from "../components/system-heartbeat-status/system-heartbeat-status.component";

export class SystemOverviewMapper {
  public static toLipoCard(systemOverviewModel: SystemOverviewModel): LipoCardModel {
    return new LipoCardModel({
      id: systemOverviewModel.id,
      route: {commands: [LipoRouteEnum.SYSTEM, systemOverviewModel.id]},
      title: systemOverviewModel.name,
      body: {
        component: SystemOverviewCardBodyComponent,
        inputs: {
          systemOverviewModel: systemOverviewModel,
          customerName: systemOverviewModel.customerName,
          isAlive: systemOverviewModel.isAlive
        }
      },
      logoUrl: systemOverviewModel.logo,
      badges: [
        {amount: systemOverviewModel.tenants, label: 'tenants', routerLink: undefined},
      ],
    });
  }

  public static toLipoTable(systemOverviewModel: SystemOverviewModel[], router: Router): LipoTableModel<any> {
    let updatedValues = systemOverviewModel.map(system => {
      return {
        ...system,
        systemHeartbeatStatusComponentInputs: {isAlive: system.isAlive} as SystemHeartbeatStatusComponent,
        lipoTableImageComponentInputs: {logoUrl: system.logo} as LipoTableImageComponent,
        lipoCompanyBadgesComponent: {
          badges: [
            {amount: system.tenants, label: 'tenants', routerLink: LipoRouteEnum.TENANT},
          ],
        } as LipoAmountBadgesComponent,
        getId: () => system.id,
      };
    });

    let tableDataSource = new MatTableDataSource<LipoModelInterface>(updatedValues);
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: '', PropertyName: 'systemHeartbeatStatusComponentInputs', Component: SystemHeartbeatStatusComponent},
      {HeaderCellName: '', PropertyName: 'lipoTableImageComponentInputs', Component: LipoTableImageComponent},
      {HeaderCellName: 'customerName', PropertyName: 'customerName'},
      {HeaderCellName: 'system', PropertyName: 'name'},
      {HeaderCellName: 'sapSystemId', PropertyName: 'sapSystemId'},
      {HeaderCellName: '', PropertyName: 'lipoCompanyBadgesComponent', Component: LipoAmountBadgesComponent},
    );

    return new LipoTableModel(
      tableDataSource,
      displayedColumns,
      undefined,
      undefined,
      async (value: LipoModelInterface) => {
        await router.navigate([LipoRouteEnum.SYSTEM, value.getId()])
      },
      {header: true, headerAction: false}
    );
  }
}
