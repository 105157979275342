import {Router} from "@angular/router";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../shared/components/models/lipo-table.model";
import {LipoTableImageComponent} from "../../shared/components/lipo-table-image/lipo-table-image.component";
import {MatTableDataSource} from "@angular/material/table";
import {LipoModelInterface} from "../../shared/interfaces/lipo-model.interface";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";
import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {AppLicenseOverviewModel} from "../components/models/app-license-overview.model";
import {
  LicenseOverviewCardBodyComponent
} from "../components/license-overview-card-body/license-overview-card-body.component";
import {DatePipe} from "@angular/common";

export class AppLicenseOverviewMapper {
  public static toLipoCard(appLicenseOverviewModel: AppLicenseOverviewModel): LipoCardModel {
    return new LipoCardModel({
      id: appLicenseOverviewModel.id,
      route: {commands: [LipoRouteEnum.SYSTEM, appLicenseOverviewModel.system.id, LipoRouteEnum.TENANT, appLicenseOverviewModel.tenant.id]},
      title: appLicenseOverviewModel.product.name,
      body: {
        component: LicenseOverviewCardBodyComponent,
        inputs: {
          appLicenseOverviewModel: appLicenseOverviewModel
        }
      },
      logoUrl: appLicenseOverviewModel.logo,
      }
    );
  }

  public static toLipoTable(appLicenseOverviewModel: AppLicenseOverviewModel[], router: Router, datePipe: DatePipe,): LipoTableModel<any> {
    let updatedValues = appLicenseOverviewModel.map(appLicense => {
      return {
        ...appLicense,
        serverName: appLicense.system.name,
        productName: appLicense.product.name,
        tenantName: appLicense.tenant.sapCompanyDB,
        lipoTableImageComponentInputs: {logoUrl: appLicense.logo} as LipoTableImageComponent,
        getId: () => appLicense.id,
      };
    });

    let tableDataSource = new MatTableDataSource<LipoModelInterface>(updatedValues);
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: '', PropertyName: 'lipoTableImageComponentInputs', Component: LipoTableImageComponent},
      {HeaderCellName: 'license', PropertyName: 'productName'},
      {HeaderCellName: 'sapServerName', PropertyName: 'serverName'},
      {HeaderCellName: 'tenant', PropertyName: 'tenantName'},
      {HeaderCellName: 'purchaseDate', PropertyName: 'purchaseDate', BodyPipes: [datePipe]},
      {HeaderCellName: 'expiryDate', PropertyName: 'expiryDate', BodyPipes: [datePipe]},
    );

    return new LipoTableModel(
      tableDataSource,
      displayedColumns,
      undefined,
      undefined,
      async (value: LipoModelInterface) => {
        let licenseModel = value as AppLicenseOverviewModel;
        await router.navigate([LipoRouteEnum.SYSTEM, licenseModel.system.id])
      },
      {header: true, headerAction: false}
    );
  }
}
