export class AribaInboundModel {
  readonly type: string = 'AribaInbound'

  Endpoint: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  SubstitutParticipantId: string = ''
  SubstitutParticipantPartnerId: string = ''
  BasicAuth: string = ''
  CardCode: string = ''
  RoutingOrder: string = ''
  RoutingOrderConfirmation: string = ''
  RoutingShipNotice: string = ''
  RoutingInvoice: string = ''
  RoutingCreditMemo: string = ''
}

export class AribaOutboundModel {
  readonly type: string = 'AribaOutbound'

  Endpoint: string = ''
  GatewayPassword: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  SubstitutParticipantId: string = ''
  SubstitutParticipantPartnerId: string = ''
  CardCode: string = ''
  RoutingOrder: string = ''
  RoutingOrderConfirmation: string = ''
  RoutingShipNotice: string = ''
  RoutingInvoice: string = ''
  RoutingCreditMemo: string = ''
  SharedSecret: string = ''
  SharedSecret_OrderConfirmation: string = ''
  SharedSecret_ShipNotice: string = ''
  SharedSecret_InvoiceRequest: string = ''
  SharedSecret_CreditMemoRequest: string = ''
}
