export class CustomerOverviewModel {
  constructor(
    public id: number | null,
    public name: string,
    public phone: string,
    public mail: string,
    public logoUrl: string | null,
    public systems: number,
    public users: number
  ) {
  }
}
