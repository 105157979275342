import {TenantDetailModel} from "../../../tenant/components/models/tenant-detail.model";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {PipeTransform} from "@angular/core";
import {SystemLicenseDetailModel} from "../models/system-license-detail.model";

export function getSystemTenantsTable(tenants: TenantDetailModel[], buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantDetailModel>(tenants)
  let selectionModel = new SelectionModel<TenantDetailModel>(true, [])
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'status', PropertyName: 'isActiveText'},
    {HeaderCellName: 'sapCompanyDB', PropertyName: 'sapCompanyDB'},
    {HeaderCellName: 'serviceName', PropertyName: 'serviceName'},
    {HeaderCellName: 'testSystem', PropertyName: 'testSystem'},
    {HeaderCellName: 'port', PropertyName: 'port'},
    {HeaderCellName: 'checkInbound', PropertyName: 'checkInbound'},
    {HeaderCellName: 'directory', PropertyName: 'directory'},
    {HeaderCellName: 'suspended', PropertyName: 'suspended'},
    {HeaderCellName: 'duifLicense', PropertyName: 'duifLicense'},
    {HeaderCellName: 'installNo', PropertyName: 'installNo'},
    {HeaderCellName: 'endpointUrl', PropertyName: 'endpointUrl'},
  );

  return new LipoTableModel<TenantDetailModel>(
    tableDataSource,
    displayedColumns,
    selectionModel,
    buttons,
    onRowClick
  );
}

export function getLicensesTable(tenants: TenantDetailModel[], datePipe: PipeTransform, buttons?: LipoButton[]): LipoTableModel<SystemLicenseDetailModel> {
  const appLicenses = tenants.flatMap(({appLicenses, sapCompanyDB}) =>
    appLicenses.map(appLicense => ({
      ...appLicense,
      sapCompanyDB,
      getId() {
        return this.tenantId;
      }
    }))
  );


  let tableDataSource = new MatTableDataSource<SystemLicenseDetailModel>(appLicenses)
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'sapCompanyDB', PropertyName: 'sapCompanyDB'},
    {HeaderCellName: 'purchaseDate', PropertyName: 'purchaseDate', BodyPipes: [datePipe]},
    {HeaderCellName: 'expiryDate', PropertyName: 'expiryDate', BodyPipes: [datePipe]},
    {HeaderCellName: 'active', PropertyName: 'isActiveText'},
  );

  return new LipoTableModel<SystemLicenseDetailModel>(
    tableDataSource,
    displayedColumns,
    undefined,
    buttons,
    undefined
  );
}
