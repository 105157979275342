import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {asyncScheduler, lastValueFrom, Observable, scheduled, toArray} from "rxjs";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {
  LipoFormControlModel,
  LipoFormPassword,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {AribaInboundModel, AribaOutboundModel} from "../models/ariba.model";
import {AribaSettingsConfiguration} from "../../../shared/services/models/configuration.model";
import {FormControl, Validators} from "@angular/forms";

export async function SetAribaInboundSettings(
  model: AribaInboundModel,
  settings: AribaSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const formGroups = await lastValueFrom(getAribaInboundFields(model, settings).pipe(toArray()));

  if (detailModel) {
    formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
  }
}

export async function SetAribaOutboundSettings(
  model: AribaOutboundModel,
  settings: AribaSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const formGroups = await lastValueFrom(getAribaOutboundFields(model, settings).pipe(toArray()));

  if (detailModel) {
    formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
  }
}

export function getAribaInboundFields(aribaInboundModel: AribaInboundModel, settings: AribaSettingsConfiguration): Observable<LipoFormGroupModel> {

  const aribaInboundFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.Endpoint, Validators.required),
      key: 'Endpoint',
      label: 'Endpoint'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.ParticipantId, Validators.required),
      key: 'ParticipantId',
      label: 'Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.SubstitutParticipantId, Validators.required),
      key: 'SubstitutParticipantId',
      label: 'Substitute Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.SubstitutParticipantPartnerId, Validators.required),
      key: 'SubstitutParticipantPartnerId',
      label: 'Substitute Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.BasicAuth, Validators.required),
      key: 'BasicAuth',
      label: 'Basic Auth'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.CardCode, Validators.required),
      key: 'CardCode',
      label: 'CardCode'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.RoutingOrder, Validators.required),
      key: 'RoutingOrder',
      label: 'Routing Order'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.RoutingOrderConfirmation, Validators.required),
      key: 'RoutingOrderConfirmation',
      label: 'Routing Order Confirmation'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.RoutingShipNotice, Validators.required),
      key: 'RoutingShipNotice',
      label: 'Routing Ship Notice'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.RoutingInvoice, Validators.required),
      key: 'RoutingInvoice',
      label: 'Routing Invoice'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaInboundModel.RoutingCreditMemo, Validators.required),
      key: 'RoutingCreditMemo',
      label: 'Routing Credit Memo'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_${settings.inbound.formKey}`,
      label: 'ARIBA Inbound',
      order: 2,
      controls: aribaInboundFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: true, expanded: true, disabled: true}
    }),
  ], asyncScheduler);
}

export function getAribaOutboundFields(aribaOutboundModel: AribaOutboundModel, settings: AribaSettingsConfiguration): Observable<LipoFormGroupModel> {
  const aribaOutboundFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.Endpoint, Validators.required),
      key: 'Endpoint',
      label: 'Endpoint'
    }),
    new LipoFormPassword({
      value: new FormControl(aribaOutboundModel.GatewayPassword, Validators.required),
      key: 'GatewayPassword',
      label: 'Gateway Password'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.SubstitutParticipantId, Validators.required),
      key: 'SubstitutParticipantId',
      label: 'Substitute Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.SubstitutParticipantPartnerId, Validators.required),
      key: 'SubstitutParticipantPartnerId',
      label: 'Substitute Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.CardCode, Validators.required),
      key: 'CardCode',
      label: 'CardCode'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.RoutingOrder, Validators.required),
      key: 'RoutingOrder',
      label: 'Routing Order'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.RoutingOrderConfirmation, Validators.required),
      key: 'RoutingOrderConfirmation',
      label: 'Routing Order Confirmation'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.RoutingShipNotice, Validators.required),
      key: 'RoutingShipNotice',
      label: 'Routing Ship Notice'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.RoutingInvoice, Validators.required),
      key: 'RoutingInvoice',
      label: 'Routing Invoice'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.RoutingCreditMemo, Validators.required),
      key: 'RoutingCreditMemo',
      label: 'Routing CreditMemo'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.SharedSecret, Validators.required),
      key: 'SharedSecret',
      label: 'SharedSecret'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.SharedSecret_OrderConfirmation, Validators.required),
      key: 'SharedSecret_OrderConfirmation',
      label: 'Shared Secret Order Confirmation'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.SharedSecret_ShipNotice, Validators.required),
      key: 'SharedSecret_ShipNotice',
      label: 'Shared Secret Ship Notice'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.SharedSecret_InvoiceRequest, Validators.required),
      key: 'SharedSecret_InvoiceRequest',
      label: 'Shared Secret Invoice Request'
    }),
    new LipoFormTextbox({
      value: new FormControl(aribaOutboundModel.SharedSecret_CreditMemoRequest, Validators.required),
      key: 'SharedSecret_CreditMemoRequest',
      label: 'Shared Secret Credit Memo Request'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_${settings.outbound.formKey}`,
      label: 'ARIBA Outbound',
      order: 3,
      controls: aribaOutboundFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: false, expanded: true, disabled: false}
    }),
  ], asyncScheduler);
}
