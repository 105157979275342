<div class="layout-column detail-table-container">
  @if(lipoTable.settings.headerAction){
  <div class="detail-table-header responsive-layout">
      <div class="detail-table-header-actions">
        @for (button of lipoTable.buttons; track $index) {
          <button mat-stroked-button [class]="button.className"
                  (click)="button.onClick()">
            @if (button.icon) {
              <mat-icon [fontSet]="button.iconFontSet" class="button-icon">{{ button.icon }}</mat-icon>
            }
            {{ button.text | translate | uppercase }}
          </button>
        }
      </div>
    @if(lipoTable.settings.search) {
      <div class="detail-table-header-search"></div>
      <div class="dense-4">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" color="accent">
          <mat-label>{{ 'search' | translate }}</mat-label>
          <input matInput [(ngModel)]="searchInput" (input)="applyFilter()">
          <mat-icon matSuffix fontIcon="search"></mat-icon>
        </mat-form-field>
      </div>
    }
  </div>
  }

  <div>
    <table mat-table [dataSource]="this.lipoTable.tableDataSource" matSort>

      <!-- Checkbox Column -->
      @if(lipoTable.selectionModel) {
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="detail-table-header-cell">
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="lipoTable.selectionModel.hasValue() && isAllSelected()"
                        [indeterminate]="lipoTable.selectionModel.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="detail-table-body-cell">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? lipoTable.selectionModel.toggle(row) : null"
                        [checked]="lipoTable.selectionModel.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      }
      <!-- Position Column -->
      @for (column of lipoTable.displayedColumns; track column) {
        <ng-container [matColumnDef]="column.PropertyName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ pipeValue(column.HeaderPipes, column.HeaderCellName) }}</th>
          <td mat-cell *matCellDef="let element">
            @if(column.Component) {
              <div>
                <ng-container *ngComponentOutlet="column.Component; inputs: element[column.PropertyName];"/>
              </div>
            } @else {
              {{ pipeValue(column.BodyPipes, element[column.PropertyName]) }}
            }
          </td>
        </ng-container>
      }

      @if (lipoTable.settings.header) {
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      }
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"
          (click)="handleClickEvent(row)" [ngClass]="{'detail-table-row-click': lipoTable.onRowClick}">
      </tr>

    </table>
  </div>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
