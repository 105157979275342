import {AppLicenseServiceModel} from "../services/models/app-license-view-service.model";
import {AppLicenseDetailModel, AppLicenseTableModel} from "../components/models/app-license-detail.model";
import {ProductDataService} from "../../product/services/product-data.service";
import {firstValueFrom} from "rxjs";

export class AppLicenseDetailMapper {
  public static toAppLicenseServiceModel(appLicenseDetailModel: AppLicenseDetailModel): AppLicenseServiceModel {
    return {
      purchaseDate: appLicenseDetailModel.purchaseDate.format('YYYY-MM-DD'),
      expiryDate: appLicenseDetailModel.expiryDate.format('YYYY-MM-DD'),
      customerId: appLicenseDetailModel.customerId ?? 0,
      productId: appLicenseDetailModel.productId ?? 0,
      productPriceId: appLicenseDetailModel.productPriceId ?? 0,
      tenantId: appLicenseDetailModel.tenantId ?? 0,
      isActive: appLicenseDetailModel.isActive ?? false,
      id: appLicenseDetailModel.id ?? 0
    }
  }

  public static async toAppLicenseTableModel(appLicenseServiceModel: AppLicenseDetailModel,
                                             productDataService: ProductDataService): Promise<AppLicenseTableModel> {
    let productId = appLicenseServiceModel.productId ?? 0
    let productData$ = productDataService.getProduct(productId)
    let product = await firstValueFrom(productData$)
    let productPrice =  product.productPrice.find(p => p.id == appLicenseServiceModel.productPriceId)

    return new AppLicenseTableModel(
      appLicenseServiceModel.purchaseDate,
      appLicenseServiceModel.expiryDate,
      product.name,
      productPrice?.name,
      productPrice?.price,
      appLicenseServiceModel.isActive,
      appLicenseServiceModel.isActiveText,
      appLicenseServiceModel.id);
  }
}
