export class ZugFerDRestModel {
  readonly type: string = 'ZUGFerDRest'

  EBillID: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  SubstitutParticipantId: string = ''
  SubstitutParticipantPartnerId: string = ''
  GatewayPassword: string = ''
  RemotePath: string = ''
  BuyerAddressMapping: string = ''
  ShipToAddressMapping: string = ''
  AdditionalReference: string = ''
  TargetFilename: string = ''
  SkipCreditNotesProcessing: boolean = false
  SuppressOrderReference: boolean = false
}

export class ZugFerDEdifactModel {
  readonly type: string = 'ZUGFerDEdifact'

  EBillID: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  SubstitutParticipantId: string = ''
  SubstitutParticipantPartnerId: string = ''
  GatewayPassword: string = ''
  BuyerAddressMapping: string = ''
  ShipToAddressMapping: string = ''
  AdditionalReference: string = ''
  TargetFilename: string = ''
  SkipCreditNotesProcessing: boolean = false
  SuppressOrderReference: boolean = false
}

export class ZugFerDMailModel {
  readonly type: string = 'ZUGFerDMail'

  EBillID: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  SubstitutParticipantId: string = ''
  SubstitutParticipantPartnerId: string = ''
  ApiKey: string = ''
  RemotePath: string = ''
  BuyerAddressMapping: string = ''
  ShipToAddressMapping: string = ''
  SkipCreditNotesProcessing: boolean = false
  TargetFilename: string = ''
  FilePattern: string = ''
  KeepFiles: boolean = false
  InvoiceMarker: string = ''
}
