import {TenantServiceModel} from "../services/models/tenant-service.model";
import {TenantDetailModel} from "../components/models/tenant-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {TenantTokenDetailMapper} from "./tenant-token-detail.mapper";
import {TenantAppSettingsDetailMapper} from "./tenant-app-settings-detail.mapper";
import {TenantUserMappingDetailMapper} from "./tenant-user-mapping-detail.mapper";
import {AppLicenseDetailMapper} from "../../licence/mappers/app-license-detail.mapper";

export class TenantDetailMapper {
  public static toTenantServiceModel (tenantDetailModel: TenantDetailModel): TenantServiceModel {
    return {
      active: tenantDetailModel.active,
      autoDeploy: tenantDetailModel.autoDeploy,
      artifactDate: tenantDetailModel.artifactDate,
      checkInbound: tenantDetailModel.checkInbound,
      directory: tenantDetailModel.directory,
      gitlabJobName: tenantDetailModel.gitlabJobName,
      gitlabProjectId: tenantDetailModel.gitlabProjectId!,
      gitlabRefName: tenantDetailModel.gitlabRefName,
      port: tenantDetailModel.port ?? 0,
      externalPort: tenantDetailModel.externalPort ?? 0,
      sapCompanyDB: tenantDetailModel.sapCompanyDB,
      sapPassword: tenantDetailModel.sapPassword,
      sapUser: tenantDetailModel.sapUser,
      serviceName: tenantDetailModel.serviceName,
      testSystem: tenantDetailModel.testSystem,
      suspended: tenantDetailModel.suspended,
      duifLicense: tenantDetailModel.duifLicense,
      installNo: tenantDetailModel.installNo,
      endpointUrl: tenantDetailModel.endpointUrl,
      allowWithoutAuth: tenantDetailModel.allowWithoutAuth,
      proxyName: tenantDetailModel.proxyName,
      appLicenses: tenantDetailModel.appLicenses.map(AppLicenseDetailMapper.toAppLicenseServiceModel),
      tokens: tenantDetailModel.tokens.map(TenantTokenDetailMapper.toTenantTokenServiceModel),
      appSettings: tenantDetailModel.appSettings.map(TenantAppSettingsDetailMapper.toTenantAppSettingsServiceModel),
      tenantUserMappings: tenantDetailModel.tenantUserMappings.map(TenantUserMappingDetailMapper.toTenantUserMappingServiceModel),
      id: tenantDetailModel.id
    }
  }

  public static toLipoDetailModel(tenant: TenantDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      tenant.sapCompanyDB,
      tenant.gitlabProjectId ? `#${tenant.gitlabProjectId}` : null,
      null,
      formModel,
      tabs
    )
  }
}
