import {MobileLogisticSettingsConfiguration} from "../../../shared/services/models/configuration.model";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {asyncScheduler, lastValueFrom, Observable, scheduled, toArray} from "rxjs";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {
  LipoFormColor,
  LipoFormControlModel,
  LipoFormSelect,
  LipoFormSlideToggle
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {validColorValidator} from "ngx-colors";
import {MobileLogisticsSettingsModel} from "../models/mobile-logistics-settings.model";

/**
 * Updates the mobile logistic settings by applying the specified configuration
 * and updates the associated detail model with the resulting form groups.
 *
 * @param {MobileLogisticsSettingsModel} model - The data model for mobile logistic settings.
 * @param {MobileLogisticSettingsConfiguration} settings - The configuration object containing settings to be applied.
 * @param {LipoDetailModel} detailModel - The detail model to update with form groups, if provided.
 * @return {Promise<void>} A promise that resolves when the settings have been applied and the model is updated.
 */
export async function setMobileLogisticSettings(
  model: MobileLogisticsSettingsModel,
  settings: MobileLogisticSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const formGroups = await lastValueFrom(getMobileLogisticSettings(model, settings).pipe(toArray()));

  if (detailModel) {
    formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
  }
}

export function getMobileLogisticSettings(model: MobileLogisticsSettingsModel, settings: MobileLogisticSettingsConfiguration): Observable<LipoFormGroupModel> {
  const companyPoliciesFields: LipoFormControlModel[] = [
    new LipoFormSlideToggle({
      value: new FormControl(model.CompanyPolicies.HideQuantities),
      key: 'HideQuantities',
      label: 'hideQuantities',
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.CompanyPolicies.PackagingItemAllocation),
      key: 'PackagingItemAllocation',
      label: 'packagingItemAllocation',
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.CompanyPolicies.ItemScanningCountingEnabled),
      key: 'ItemScanningCountingEnabled',
      label: 'itemScanningCountingEnabled',
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.CompanyPolicies.ItemScanningPicklistEnabled),
      key: 'ItemScanningPicklistEnabled',
      label: 'itemScanningPicklistEnabled',
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.CompanyPolicies.ItemScanningGoodsReceiptEnabled),
      key: 'ItemScanningGoodsReceiptEnabled',
      label: 'itemScanningGoodsReceiptEnabled',
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.CompanyPolicies.BulkScanEnabled),
      key: 'BulkScanEnabled',
      label: 'bulkScanEnabled',
    }),
  ]

  const mobileLogisticSettingsBaseFields: LipoFormControlModel[] = [
    new LipoFormColor({
      value: new FormControl(model.NavBarColor, [Validators.required, validColorValidator()]),
      key: 'NavBarColor',
      label: 'navBarColor',
    }),
    new LipoFormColor({
      value: new FormControl(model.NavBarTextAccentColor, [Validators.required, validColorValidator()]),
      key: 'NavBarTextAccentColor',
      label: 'navBarTextAccentColor',
    }),
    new LipoFormColor({
      value: new FormControl(model.NavBarTextColor, [Validators.required, validColorValidator()]),
      key: 'NavBarTextColor',
      label: 'navBarTextColor',
    }),
    new LipoFormSelect({
      value: new FormControl(model.Subscriptions.split('|'), [Validators.required]),
      key: 'Subscriptions',
      label: 'subscription.type',
      settings: {
        options: settings.subscriptions.map(it =>  ({key: it.translation, value: it.name})),
        multiSelect: true
      }
    })
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_base`,
      label: settings.formKey,
      order: 2,
      controls: mobileLogisticSettingsBaseFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: true, expanded: true, disabled: true}
    }),
    new LipoFormGroupModel({
      key: `${settings.formKey}_companyPolicy`,
      label: 'companyPolicy',
      order: 3,
      controls: companyPoliciesFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: false, expanded: true, disabled: false}
    }),
  ], asyncScheduler);
}
