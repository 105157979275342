import {Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {SystemOverviewModel} from "../../services/models/system-overview.model";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {NgClass} from "@angular/common";

@Component({
  selector: 'du-system-overview-card-body',
  standalone: true,
    imports: [
    TranslateModule,
    MatIcon,
    MatTooltip,
    NgClass
  ],
  templateUrl: './system-overview-card-body.component.html',
  styleUrl: './system-overview-card-body.component.scss'
})
export class SystemOverviewCardBodyComponent {
  @Input() systemOverviewModel!: SystemOverviewModel;
  @Input() customerName!: string;
  @Input() isAlive: boolean = false;
}
