import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {TenantTokenDetailModel} from "./tenant-token-detail.model";
import {TenantAppSettingsDetailModel} from "./tenant-app-settings-detail.model";
import {TenantUserMappingDetailModel} from "./tenant-user-mapping-detail.model";
import {AppLicenseDetailModel} from "../../../licence/components/models/app-license-detail.model";

export class TenantDetailModel implements LipoModelInterface {

  constructor(
    public active: boolean = false,
    public isActiveText: 'active' | 'idle' | null = "idle",
    public autoDeploy: boolean = false,
    public autoDeployText: 'yes' | 'no' | null = "no",
    public artifactDate: string | null = null,
    public checkInbound: boolean = false,
    public directory: string | null = null,
    public gitlabJobName:string | null = null,
    public gitlabProjectId: number | null = null,
    public gitlabRefName: string | null = null,
    public port: number | null = null,
    public externalPort: number | null = null,
    public sapCompanyDB: string = '',
    public sapPassword: string | null = null,
    public sapUser: string | null = null,
    public serviceName: string |null = null,
    public testSystem: boolean = false,
    public suspended: boolean = false,
    public duifLicense: string | null = null,
    public installNo: string | null = null,
    public endpointUrl: string | null = null,
    public allowWithoutAuth: boolean = false,
    public proxyName: string | null = null,
    public appLicenses: AppLicenseDetailModel[] = [],
    public tokens: TenantTokenDetailModel[] = [],
    public appSettings: TenantAppSettingsDetailModel[] = [],
    public tenantUserMappings: TenantUserMappingDetailModel[] = [],
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}
