import {PartnerDetailModel} from "../../partner/components/models/partner-detail.model";
import {FormGroup} from "@angular/forms";
import {Address} from "../models/lipo-address.model";
import {CustomerDetailModel} from "../../customer/components/models/customer-detail.model";
import {SystemDetailModel, SystemSaveDetailModel} from "../../system/components/models/system-detail.model";
import {CustomerDetailMapper} from "../../customer/mappers/customer-detail.mapper";
import {PartnerDetailMapper} from "../../partner/mappers/partner-detail.mapper";
import {PartnerServiceModel} from "../../partner/services/models/partner-service.model";
import {CustomerServiceModel} from "../../customer/services/models/customer-service.model";
import {SystemSaveServiceModel, SystemServiceModel} from "../../system/services/models/system-service.model";
import {SystemSaveDetailMapper} from "../../system/mappers/system-save-detail.mapper";
import {TenantServiceModel} from "../../tenant/services/models/tenant-service.model";
import {TenantDetailModel} from "../../tenant/components/models/tenant-detail.model";
import {TenantDetailMapper} from "../../tenant/mappers/tenant-detail.mapper";
import {SystemDetailMapper} from "../../system/mappers/system-detail.mapper";
import {ProductServiceModel} from "../../product/services/models/product-service.model";
import {ProductDetailMapper} from "../../product/mappers/product-detail.mapper";
import {ProductDetailModel} from "../../product/components/models/product-detail.model";
import {ProductPriceDetailModel} from "../../productPrice/components/models/product-price-detail.model";
import {ProductPriceServiceModel} from "../../productPrice/services/models/product-price-service.model";
import {ProductPriceDetailMapper} from "../../productPrice/mappers/product-price-detail.mapper";
import {UserServiceModel, UserServiceSaveModel} from "../../user/services/models/user-service.model";
import {UserDetailModel, UserSaveDetailModel} from "../../user/components/models/user-detail.model";
import {UserSaveDetailMapper} from "../../user/mappers/user-save-detail.mapper";
import {TenantTokenServiceSaveModel} from "../../tenant/services/models/tenant-token-service.model";
import {TenantTokenDetailSaveModel} from "../../tenant/components/models/tenant-token-detail.model";
import {TenantTokenSaveDetailMapper} from "../../tenant/mappers/tenant-token-detail.mapper";
import {TenantUserMappingServiceModel} from "../../tenant/services/models/tenant-user-mapping-service.model";
import {TenantUserMappingDetailModel} from "../../tenant/components/models/tenant-user-mapping-detail.model";
import {TenantUserMappingDetailMapper} from "../../tenant/mappers/tenant-user-mapping-detail.mapper";
import {TenantAppSettingsServiceModel} from "../../tenant/services/models/tenant-app-settings-service.model";
import {TenantAppSettingsDetailModel} from "../../tenant/components/models/tenant-app-settings-detail.model";
import {TenantAppSettingsDetailMapper} from "../../tenant/mappers/tenant-app-settings-detail.mapper";
import {UserDetailMapper} from "../../user/mappers/user-detail.mapper";
import moment from "moment";
import {AppLicenseSettingsConfiguration} from "../services/models/configuration.model";
import {toStrDict} from "../../utils/str-dict";
import {AppSettingsFormMapper} from "./appSettings-form.mapper";

declare module '@angular/forms' {
  interface FormGroup {
    getDefaultFormGroup(): FormGroup | undefined;
  }
}

FormGroup.prototype.getDefaultFormGroup = function (): FormGroup | undefined {
  const formGroupControls = Object.keys(this.value || {}).reduce((controls, key) => {
    const part = this.get(key) as FormGroup;
    if (part?.controls) {
      return { ...controls, ...part.controls };
    }
    return controls;
  }, {});

  return new FormGroup(formGroupControls);
}

export class LipoFormMapper {

  public static toPartnerServiceModel(formGroup: FormGroup, logoUrl: string | null, addresses: Address[] = [], customers: CustomerDetailModel[] = []): PartnerServiceModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as PartnerDetailModel;

    fieldValue.logoUrl = logoUrl;
    fieldValue.addresses = addresses;
    fieldValue.customers = customers;

    return PartnerDetailMapper.toPartnerServiceModel(fieldValue);
  }

  public static toCustomerServiceModel(formGroup: FormGroup, logoUrl: string | null, addresses: Address[] = [], systems: SystemDetailModel[] = [], users: UserDetailModel[] = []): CustomerServiceModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as CustomerDetailModel;

    fieldValue.logoUrl = logoUrl;
    fieldValue.addresses = addresses;
    fieldValue.systems = systems;
    fieldValue.users = users;

    return CustomerDetailMapper.toCustomerServiceModel(fieldValue);
  }

  public static toSystemSaveServiceModel(formGroup: FormGroup): SystemSaveServiceModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as SystemSaveDetailModel;

    return SystemSaveDetailMapper.toSystemSaveServiceModel(fieldValue)
  }

  public static toSystemServiceModel(formGroup: FormGroup, tenantDetails: TenantDetailModel[] = []): SystemServiceModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as SystemDetailModel;
    fieldValue.tenants = tenantDetails;

    return SystemDetailMapper.toSystemServiceModel(fieldValue)
  }
  public static toProductServiceModel(formGroup: FormGroup, productPrice: ProductPriceDetailModel[] = []): ProductServiceModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as ProductDetailModel;
    fieldValue.productPrice = productPrice

    return ProductDetailMapper.toProductServiceModel(fieldValue);
  }

  public static toProductPriceSaveModel(formGroup: FormGroup): ProductPriceServiceModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as ProductPriceDetailModel

    return ProductPriceDetailMapper.toProductPriceServiceModel(fieldValue)
  }

  public static toTenantSaveModel(formGroup: FormGroup, old?: TenantDetailModel): TenantServiceModel {

    let sap = formGroup?.get('sap')?.getRawValue();
    let artifact = formGroup?.get('artifact')?.getRawValue();
    let gitlab = formGroup?.get('gitlab')?.getRawValue();
    let technical = formGroup?.get('technical')?.getRawValue();
    let checkBoxes = formGroup?.get('checkBoxes')?.getRawValue();
    let connection = formGroup?.get('connection')?.getRawValue();

    let tenant = new TenantDetailModel(
      checkBoxes.active,
      null,
      checkBoxes.autoDeploy,
      null,
      artifact.artifactDate,
      checkBoxes.checkInbound,
      artifact.directory ?? old?.artifactDate,
      gitlab.gitlabJobName ?? old?.gitlabJobName,
      gitlab.gitlabProjectId ?? old?.gitlabProjectId,
      gitlab.gitlabRefName ?? old?.gitlabRefName,
      connection.port,
      connection.externalPort,
      sap.sapCompanyDB,
      sap.sapPassword,
      sap.sapUser,
      technical.serviceName ?? old?.serviceName,
      checkBoxes.testSystem,
      checkBoxes.suspended,
      technical.duifLicense ?? old?.duifLicense,
      technical.installNo ?? old?.installNo,
      connection.endpointUrl,
      old?.allowWithoutAuth,
      connection.proxyName,
      old?.appLicenses,
      old?.tokens,
      old?.appSettings,
      old?.tenantUserMappings,
      old?.id
    )

    return TenantDetailMapper.toTenantServiceModel(tenant);
  }

  public static toTenantUserMappingServiceModel(formGroup: FormGroup, tenantUserMappingId: number | null, selectedTenantAppSettings: TenantAppSettingsServiceModel[], appSettingsConfig: AppLicenseSettingsConfiguration): TenantUserMappingServiceModel {
    let fieldValue = formGroup.get('tenantUserMapping')?.getRawValue() as TenantUserMappingDetailModel
    fieldValue.sapUserCode = fieldValue.sapUserCode.toString()
    fieldValue.id = tenantUserMappingId
    fieldValue.appSettings = []

    for (let selectedTenantAppSetting of selectedTenantAppSettings) {
      fieldValue.appSettings.push(
        new TenantAppSettingsDetailModel(
          selectedTenantAppSetting.appName,
          selectedTenantAppSetting.description,
          toStrDict(AppSettingsFormMapper(formGroup, selectedTenantAppSetting.appName, appSettingsConfig)),
          0
        )
      )
    }

    return TenantUserMappingDetailMapper.toTenantUserMappingServiceModel(fieldValue);
  }

  public static toTenantTokenSaveServiceModel(formGroup: FormGroup): TenantTokenServiceSaveModel{
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as TenantTokenDetailSaveModel;

    let validUntilControl = formGroup.get('validUntil');

    fieldValue.validUntil = validUntilControl?.value
      ? moment(validUntilControl.value, 'DD.MM.YYYY')
      : moment('31.12.2099', 'DD.MM.YYYY');

    return TenantTokenSaveDetailMapper.toTenantTokenServiceModel(fieldValue);
  }

  public static toUserSaveServiceModel(formGroup: FormGroup): UserServiceSaveModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as UserSaveDetailModel;

    return UserSaveDetailMapper.toUserSaveServiceModel(fieldValue);
  }

  public static toUserServiceModel(formGroup: FormGroup): UserServiceModel {
    let fieldValue = formGroup.getDefaultFormGroup()?.getRawValue() as UserDetailModel;

    return UserDetailMapper.toUserServiceModel(fieldValue);
  }

  public static toTenantAppSettingsServiceModel(formGroup: FormGroup, tenantAppSettingsId: number | null, appSettings: AppLicenseSettingsConfiguration): TenantAppSettingsServiceModel {
    let appSettingsControl = formGroup.get(appSettings.formKey) as FormGroup;

    let appSettingsModel = appSettingsControl?.getRawValue() as TenantAppSettingsDetailModel

    appSettingsModel.id = tenantAppSettingsId

    appSettingsModel.attributes = toStrDict(AppSettingsFormMapper(formGroup, appSettingsModel.appName, appSettings))

    return TenantAppSettingsDetailMapper.toTenantAppSettingsServiceModel(appSettingsModel);
  }
}
