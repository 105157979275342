import {SystemServiceModel} from "../services/models/system-service.model";
import {SystemDetailModel} from "../components/models/system-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {TenantDetailMapper} from "../../tenant/mappers/tenant-detail.mapper";

export class SystemDetailMapper {
  public static toLipoDetailModel(system: SystemDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      system.name,
      system.id ? `#${system.id}` : null,
      null,
      formModel,
      tabs
    )
  }

  public static toSystemServiceModel(systemDetailModel: SystemDetailModel): SystemServiceModel {
    return {
      sapSystemId: systemDetailModel.sapSystemId,
      name: systemDetailModel.name,
      hardwareKey: systemDetailModel.hardwareKey,
      initialDate: systemDetailModel.initialDate,
      isAlive: systemDetailModel.isAlive,
      logo: systemDetailModel.logoUrl,
      customer: systemDetailModel.customer,
      identity: systemDetailModel.identity,
      tenants: systemDetailModel.tenants.map(TenantDetailMapper.toTenantServiceModel),
      id: systemDetailModel.id,
      basePath: systemDetailModel.basePath,
      dbPassword: systemDetailModel.dbPassword,
      dbUserName: systemDetailModel.dbUserName,
      sapServerName: systemDetailModel.sapServerName,
      sapServerType: systemDetailModel.sapServerType,
      sapLicServerName: systemDetailModel.sapLicServerName,
      sapServiceLayerName: systemDetailModel.sapServiceLayerName,
      lastHeartbeat: systemDetailModel.lastHeartbeat,
    } as SystemServiceModel;
  }
}
