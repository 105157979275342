import {LipoFormControlModel} from "./lipo-form-control.model";
import {LipoButton} from "./lipo-button";

export class LipoFormGroupModel {
  key: string;
  label: string | null;
  order: number;
  controls: LipoFormControlModel[];
  buttons: LipoButton[];
  columns: number;
  background: boolean;
  expansionActive: boolean;
  expansion: {
    background: boolean,
    hideToggle: boolean,
    expanded: boolean,
    disabled: boolean,
  }
  style: string;

  constructor(options: {
    key?: string;
    label?: string;
    order?: number;
    controls?: LipoFormControlModel[];
    buttons?: LipoButton[];
    columns?: number;
    cardBackground?: boolean;
    expansionActive?: boolean;
    expansion?: {
      background?: boolean,
      hideToggle?: boolean,
      expanded?: boolean,
      disabled?: boolean,
    }
    style?: string;
  } = {}) {
    this.key = options.key ?? 'default';
    this.label = options.label ?? null;
    this.order = options.order ?? 1;
    this.controls = options.controls ?? [];
    this.buttons = options.buttons ?? [];
    this.columns = options.columns ?? 2;
    this.background = options.cardBackground ?? false;
    this.expansionActive = options.expansionActive ?? false;
    this.expansion = {
      background: options.expansion?.background ?? true,
      hideToggle: options.expansion?.hideToggle ?? false,
      expanded: options.expansion?.expanded ?? true,
      disabled: options.expansion?.disabled ?? false,
    };
    this.style = options.style ?? '';
  }
}
