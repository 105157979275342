import {Component, inject, Input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {ConfigurationService} from "../../services/configuration.service";

@Component({
  selector: 'du-lipo-table-image',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './lipo-table-image.component.html',
  styleUrl: './lipo-table-image.component.scss'
})
export class LipoTableImageComponent {
  @Input() logoUrl?: string = undefined;

  defaultLogo: string = inject(ConfigurationService).configuration.app.defaultLogo;

  get getLogoUrl() {
    if(this.logoUrl === 'null') return this.defaultLogo;
    return this.logoUrl ?? this.defaultLogo;
  }
}
