export class TransferSFTPModel {
  readonly type: string = 'TransferSFTP'

  Endpoint: string = ''
  GatewayPassword: string = ''
  ParticipantId: string = ''
  ParticipantPartnerId: string = ''
  FtpPassword: string = ''
  BaseFolder: string = ''
  BaseSubFolder: string = ''
}
