import {PartnerOverviewServiceModel, PartnerServiceModel} from "../services/models/partner-service.model";
import {PartnerOverviewModel} from "../components/models/partner-overview.model";
import {PartnerDetailModel} from "../components/models/partner-detail.model";
import {CustomerServiceMapper} from "../../customer/mappers/customer-service.mapper";

export class PartnerServiceMapper {
  public static toPartnerOverviewMapper(partnerServiceModel: PartnerOverviewServiceModel): PartnerOverviewModel {
    return new PartnerOverviewModel(
      partnerServiceModel.id,
      partnerServiceModel.name,
      partnerServiceModel.phone,
      partnerServiceModel.logo,
      partnerServiceModel.mail,
      partnerServiceModel.customers,
      partnerServiceModel.systems,
      partnerServiceModel.licenses
    )
  }

  public static toPartnerDetailModel(partnerServiceModel: PartnerServiceModel): PartnerDetailModel {
    return new PartnerDetailModel(
      partnerServiceModel.id,
      partnerServiceModel.name,
      partnerServiceModel.mail,
      partnerServiceModel.phone,
      partnerServiceModel.addresses,
      partnerServiceModel.customers.map(CustomerServiceMapper.toCustomerDetailModel),
      partnerServiceModel.logo
    )
  }
}
