import {StoreLinkSettingsConfiguration} from "../../../shared/services/models/configuration.model";
import {asyncScheduler, forkJoin, lastValueFrom, Observable, scheduled, toArray} from "rxjs";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {
  LipoFormControlModel,
  LipoFormNumeric,
  LipoFormPassword,
  LipoFormSlideToggle,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {
  ShopifySettingsModel,
  ShopwareSettingsModel,
  StoreLinkSettingsModel,
  StoreLinkSettingsServiceModel
} from "../models/store-link-settings.model";
import {
  mapToShopifySettingsModel,
  mapToShopwareSettingsModel,
  mapToStoreLinkSettingsModel
} from "../../mappers/store-link.mapper";

export async function setStoreLinkSettings(
  storeLinkServiceModel: StoreLinkSettingsServiceModel,
  settings: StoreLinkSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const observables = [
    getStoreLinkSettings(mapToStoreLinkSettingsModel(storeLinkServiceModel), settings),
    getShopifySettings(mapToShopifySettingsModel(storeLinkServiceModel), settings),
    getShopwareSettings(mapToShopwareSettingsModel(storeLinkServiceModel), settings),
  ].map(ob$ => ob$.pipe(toArray()));

  const allFormGroups = await lastValueFrom(forkJoin(observables));

  if (detailModel) {
    allFormGroups.forEach(formGroups => {
      formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
    });
  }
}

export function getStoreLinkSettings(model: StoreLinkSettingsModel, settings: StoreLinkSettingsConfiguration): Observable<LipoFormGroupModel> {
  const storeLinkFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.JSONataHost, Validators.required),
      key: 'JSONataHost',
      label: 'JSONata Host'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.JSONataUser, Validators.required),
      key: 'JSONataUser',
      label: 'JSONata User'
    }),
    new LipoFormPassword({
      value: new FormControl(model.JSONataPassword, Validators.required),
      key: 'JSONataPassword',
      label: 'JSONata Password'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.JsonPath, Validators.required),
      key: 'JsonPath',
      label: 'Json Path'
    }),
  ]

  const storeLinkOptionalFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.DefaultPriceList),
      key: 'DefaultPriceList',
      label: 'Default Price List'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.DefaultWarehouse),
      key: 'DefaultWarehouse',
      label: 'Default Warehouse'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.PrePaymentCreateReservedInvoice),
      key: 'PrePaymentCreateReservedInvoice',
      label: 'Pre Payment Create Reserved Invoice',
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.PrePaymentCreateDownPayment),
      key: 'PrePaymentCreateDownPayment',
      label: 'Pre Payment Create Down Payment',
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.PrePaymentCreateIncomingPayment),
      key: 'PrePaymentCreateIncomingPayment',
      label: 'Pre Payment Create Incoming Payment',
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_base`,
      label: 'Store Link',
      order: 2,
      controls: storeLinkFields,
      cardBackground: true
    }),
    new LipoFormGroupModel({
      key: `${settings.formKey}_optional`,
      label: 'Optional',
      order: 3,
      controls: storeLinkOptionalFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: false, expanded: true, disabled: false}
    }),
  ], asyncScheduler);
}

export function getShopifySettings(model: ShopifySettingsModel, settings: StoreLinkSettingsConfiguration): Observable<LipoFormGroupModel> {
  const shopifyFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.ShopifyHost),
      key: 'ShopifyHost',
      label: 'Host'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShopifyToken),
      key: 'ShopifyToken',
      label: 'Token'
    }),
    new LipoFormNumeric({
      value: new FormControl(model.ShopifyStockBatchSize),
      settings: {min: 1, max: 250},
      key: 'ShopifyStockBatchSize',
      label: 'Stock Batch Size'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.CreateBusinessPartnerFromSalesOrder),
      key: 'CreateBusinessPartnerFromSalesOrder',
      label: 'Create Business Partner From Sales Order'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_shopify`,
      label: 'Shopify',
      order: 4,
      controls: shopifyFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: false, expanded: false, disabled: false}
    }),
  ], asyncScheduler);
}

export function getShopwareSettings(model: ShopwareSettingsModel, settings: StoreLinkSettingsConfiguration): Observable<LipoFormGroupModel> {
  const shopwareFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.ShopwareHost),
      key: 'ShopwareHost',
      label: 'Host'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShopwareTokenUrl),
      key: 'ShopwareTokenUrl',
      label: 'Token Url'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShopwareTokenUser),
      key: 'ShopwareTokenUser',
      label: 'Token User'
    }),
    new LipoFormPassword({
      value: new FormControl(model.ShopwareTokenPassword),
      key: 'ShopwareTokenPassword',
      label: 'Token Password'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShopwareSyncApiUrl),
      key: 'ShopwareSyncApiUrl',
      label: 'Sync API Url'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.CreateContainerProductForVariantGroup),
      key: 'CreateContainerProductForVariantGroup',
      label: 'Create Container Product For Variant Group'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.IsB2B),
      key: 'IsB2B',
      label: 'Is B2B'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_shopware`,
      label: 'Shopware',
      order: 6,
      controls: shopwareFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: false, expanded: false, disabled: false}
    }),
  ], asyncScheduler);
}
