import {Component, OnInit} from '@angular/core';
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {ProductDataService} from "../../services/product-data.service";
import {ProductServiceMapper} from "../../mappers/product-service.mapper";
import {ProductOverviewMapper} from "../../mappers/product-overview.mapper";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {Router} from "@angular/router";
import {ProgressService} from "../../../shared/services/progress.service";
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'du-product-overview',
  standalone: true,
  imports: [
    LipoOverviewComponent
  ],
  templateUrl: './product-overview.component.html',
  styleUrl: './product-overview.component.scss'
})
export class ProductOverviewComponent implements OnInit {
  productOverviewModel?: LipoOverviewModel

  constructor(
    private _productService: ProductDataService,
    private router: Router,
    protected _progressService: ProgressService,
    private keycloakService: KeycloakService,
  ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this.checkUserEmail().then(
      (isAllowed) => {
        this.initializeOverviewData(isAllowed);
      }
    );
  }

  initializeOverviewData(isAllowed: boolean) {
    this._productService.getProducts().subscribe({
      next: products => {
        let productsOverview = products.map(ProductServiceMapper.toProductOverviewMapper)
        let productCards = productsOverview.map(ProductOverviewMapper.toLipoCardModel)
        let productTable = ProductOverviewMapper.toLipoTable(productsOverview, this.router)

        let buttonAdd = isAllowed ? LipoButton.build({
          text: "button.product.add",
          icon: "add",
          onClick: async () => await this.router.navigate([LipoRouteEnum.PRODUCT, 'new'])
        }) : []

        this.productOverviewModel = {
          title: 'products',
          buttons: buttonAdd,
          cards: productCards,
          table: productTable,
        }
      },
      complete: () => this._progressService.stopLoading()
    })
  }

  async checkUserEmail(): Promise<boolean> {
    try {
      const profile = await this.keycloakService.loadUserProfile();
      return profile.email?.endsWith('@dataunit.ch') ?? false;
    } catch {
      return false;
    }
  }
}
