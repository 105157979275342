<mat-card class="card" [ngClass]="{'is-focusable': !disabled}" role="button" (click)="!disabled && navigateToRoute()"
          mat-ripple
          [matRippleDisabled]="disabled">
  <mat-card-header class="card-header">
    <div class="card-header-img-container">
      <img class="card-header-img"
           [ngSrc]="content.logoUrl ?? defaultLogo"
           alt="{{content.title}} logo"
           fill>
    </div>
  </mat-card-header>

  <mat-divider class="card-divider"/>

  <mat-card-content class="card-content">
    @if(content.title) {
      <span class="card-content-title">
        {{ content.title | translate }}
      </span>
    }
    @if (content.body) {
      <ng-container *ngComponentOutlet="content.body.component; inputs: content.body.inputs;"/>
    }

    @if (content.badges.length > 0) {
      <div class="card-content-badges" (mouseenter)="disabled = true" (mouseleave)="disabled = false">
        <du-lipo-amount-badges [badges]="content.badges"/>
      </div>
    }
  </mat-card-content>
</mat-card>
