@if (setupFormGroup && overviewFormGroup) {
  <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" >
    <div mat-dialog-title class="license-wizard-dialog-header">
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content class="license-wizard-dialog">
      <mat-stepper linear #stepper class="license-wizard-stepper" [@.disabled]="true"
                   (selectedIndexChange)="onStepChange($event)">

        <mat-step [stepControl]="setupFormGroup" [editable]="true">
          <form [formGroup]="setupFormGroup" class="form-colum">
            <ng-template matStepLabel>{{ "setup" | translate }}</ng-template>

            <mat-form-field>
              <mat-label>{{ "system" | translate }}</mat-label>
              <mat-select [formControlName]="'systemCtrl'"
                          (selectionChange)="onSystemSelected($event.value)">
                <mat-select-trigger>
                  {{ getSelectedSystem() }}
                </mat-select-trigger>
                @for (system of systems; track system) {
                  <mat-option [value]="system.id">
                    <p style="margin-bottom: 0 !important;">{{ system.name }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "tenant" | translate }}</mat-label>
              <mat-select [formControlName]="'tenantCtrl'">
                @for (tenant of tenants; track tenant) {
                  <mat-option [value]="tenant.id">
                    {{ tenant.sapCompanyDB }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "product" | translate }}</mat-label>
              <mat-select [formControlName]="'productCtrl'" (selectionChange)="onProductSelected($event.value)">
                @for (product of products; track product) {
                  <mat-option [value]="product.id">
                    {{ product.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "price" | translate }}</mat-label>
              <mat-select [formControlName]="'priceCtrl'">
                <mat-select-trigger>
                  {{ getSelectedProductPrice() }}
                </mat-select-trigger>
                @for (productPrice of productPrices; track productPrice) {
                  <mat-option [value]="productPrice.id">
                    <p style="margin-bottom: 0 !important;"> {{ productPrice.name }}</p>
                    <p class="mat-body-small">{{ productPrice.price }} {{ productPrice.currency }} - {{ 'subscription.' + productPrice.type.toLowerCase() | translate }}</p>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>

          </form>
        </mat-step>

        <mat-step [stepControl]="overviewFormGroup" [editable]="true">
          <form [formGroup]="overviewFormGroup" class="form-colum">
            <ng-template matStepLabel>{{ "activate" | translate }}</ng-template>

            <table class="license-wizard-submit-table">
              <tr>
                <th>{{ 'system' | translate }}</th>
                <td>{{ getSelectedTenant() }}</td>
              </tr>
              <tr>
                <th>{{ 'tenant' | translate }}</th>
                <td>{{ getSelectedSystem() }}</td>
              </tr>
              <tr>
                <th>{{ 'product' | translate }}</th>
                <td>{{ getSelectedProduct() }}</td>
              </tr>
              <tr>
                <th>{{ 'price' | translate }}</th>
                <td>{{ getSelectedProductPrice() }}</td>
              </tr>
            </table>

            <div class="license-wizard-approve-checkbox">
              <mat-checkbox #acceptCheckbox [formControlName]="'acceptCtrl'">{{'licenseWizard.approve' | translate}}</mat-checkbox>
            </div>

          </form>
        </mat-step>

      </mat-stepper>
      <mat-dialog-actions class="license-wizard-dialog-action">
        @if (hasPreviousStep(stepper)) {
          <button mat-button (click)="stepper.previous()">{{ "button.back" | translate | uppercase }}</button>
        }

        @if (hasNextStep(stepper)) {
          <button mat-button (click)="stepper.next()" [disabled]="!isCurrentStepValid(stepper)">{{ "button.next" | translate | uppercase }}</button>

        }

        @if (!hasNextStep(stepper)) {
          <button mat-flat-button (click)="onSubmit()" (keydown)="onSubmit()"
                  [disabled]="setupFormGroup.invalid || overviewFormGroup.invalid || _isSavingAppLicense">
            @if (_isSavingAppLicense) {
              <mat-spinner [diameter]="24"></mat-spinner>
            } @else {
              {{ "button.activate" | translate | uppercase }}
            }
          </button>
        }
      </mat-dialog-actions>
    </mat-dialog-content>
  </div>

}
