import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";

export class SystemOverviewModel implements LipoModelInterface{
  constructor(
    public id: number | null,
    public name: string,
    public hardwareKey: string | null,
    public logo: string | null,
    public tenants: number,
    public sapSystemId: string | null,
    public customerName: string | null,
    public isAlive: boolean = false
  ) {
  }

  getId(): number | string | null {
    return this.id;
  }
}
