import {EBillSettingsConfiguration} from "../../../shared/services/models/configuration.model";
import {asyncScheduler, forkJoin, lastValueFrom, Observable, scheduled, toArray} from "rxjs";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {
  LipoFormControlModel,
  LipoFormPassword,
  LipoFormSlideToggle,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {YellowBillMailModel, YellowBillRestModel} from "../models/ebill/yellow-bill.model";

export async function setYellowRestBillSettings(
  model: YellowBillRestModel,
  settings: EBillSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const observables = [
    getYellowRestBill(model, settings),
  ].map(ob$ => ob$.pipe(toArray()));

  const allFormGroups = await lastValueFrom(forkJoin(observables));

  if (detailModel) {
    allFormGroups.forEach(formGroups => {
      formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
    });
  }
}

export async function setYellowMailBillSettings(
  model: YellowBillMailModel,
  settings: EBillSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const observables = [
    getYellowMailBill(model, settings),
  ].map(ob$ => ob$.pipe(toArray()));

  const allFormGroups = await lastValueFrom(forkJoin(observables));

  if (detailModel) {
    allFormGroups.forEach(formGroups => {
      formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
    });
  }
}

export function getYellowRestBill(model: YellowBillRestModel, settings: EBillSettingsConfiguration): Observable<LipoFormGroupModel> {
  const yellBillRestFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.EBillID, Validators.required),
      key: 'EBillID',
      label: 'id'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantId, Validators.required),
      key: 'ParticipantId',
      label: 'Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantId),
      key: 'SubstitutParticipantId',
      label: 'Substitute Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantPartnerId),
      key: 'SubstitutParticipantPartnerId',
      label: 'Substitute Participant Partner ID'
    }),
    new LipoFormPassword({
      value: new FormControl(model.GatewayPassword, Validators.required),
      key: 'GatewayPassword',
      label: 'Gateway Password'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.RemotePath, Validators.required),
      key: 'RemotePath',
      label: 'Remote Path'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.BuyerAddressMapping, Validators.required),
      key: 'BuyerAddressMapping',
      label: 'Buyer Address Mapping'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShipToAddressMapping, Validators.required),
      key: 'ShipToAddressMapping',
      label: 'Ship To Address Mapping'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.TargetFilename, Validators.required),
      key: 'TargetFilename',
      label: 'Target Filename'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.SkipCreditNotesProcessing),
      key: 'SkipCreditNotesProcessing',
      label: 'Skip Credit Notes Processing'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.MapOrderReferenceToAccountAssignment),
      key: 'MapOrderReferenceToAccountAssignment',
      label: 'Map Order Reference To Account Assignment'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_${settings.yellowBillRest.formKey}_base`,
      label: 'Yellow Bill Rest',
      controls: yellBillRestFields,
      cardBackground: true,
    }),
  ], asyncScheduler);
}

export function getYellowMailBill(model: YellowBillMailModel, settings: EBillSettingsConfiguration): Observable<LipoFormGroupModel> {
  const yellBillMailFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.EBillID, Validators.required),
      key: 'EBillID',
      label: 'id'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantId, Validators.required),
      key: 'ParticipantId',
      label: 'Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantId),
      key: 'SubstitutParticipantId',
      label: 'Substitute Participant ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.SubstitutParticipantPartnerId),
      key: 'SubstitutParticipantPartnerId',
      label: 'Substitute Participant Partner ID'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ApiKey, Validators.required),
      key: 'ApiKey',
      label: 'API Key'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.RemotePath, Validators.required),
      key: 'RemotePath',
      label: 'Remote Path'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.BuyerAddressMapping, Validators.required),
      key: 'BuyerAddressMapping',
      label: 'Buyer Address Mapping'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ShipToAddressMapping, Validators.required),
      key: 'ShipToAddressMapping',
      label: 'Ship To Address Mapping'
    }),
    new LipoFormSlideToggle({
      value: new FormControl(model.SkipCreditNotesProcessing, Validators.required),
      key: 'SkipCreditNotesProcessing',
      label: 'Skip Credit Notes Processing'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.TargetFilename, Validators.required),
      key: 'TargetFilename',
      label: 'Target Filename'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.FilePattern, Validators.required),
      key: 'FilePattern',
      label: 'File Pattern'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.KeepFiles, Validators.required),
      key: 'KeepFiles',
      label: 'Keep Files'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.InvoiceMarker, Validators.required),
      key: 'InvoiceMarker',
      label: 'Invoice Marker'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: `${settings.formKey}_${settings.yellowBillMail.formKey}_base`,
      label: 'Yellow Bill Mail',
      controls: yellBillMailFields,
      cardBackground: true,
    }),
  ], asyncScheduler);
}
