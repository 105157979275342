import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {asyncScheduler, lastValueFrom, Observable, scheduled, toArray} from "rxjs";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {
  LipoFormControlModel,
  LipoFormPassword,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {TransferSFTPConfiguration} from "../../../shared/services/models/configuration.model";
import {FormControl, Validators} from "@angular/forms";
import {TransferSFTPModel} from "../models/transfereSFTP.model";

export async function setTransferSFTPSettings(
  model: TransferSFTPModel,
  settings: TransferSFTPConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const formGroups = await lastValueFrom(getTransferSFTPFields(model, settings).pipe(toArray()));

  if (detailModel) {
    formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
  }
}

export function getTransferSFTPFields(model: TransferSFTPModel, settings: TransferSFTPConfiguration): Observable<LipoFormGroupModel> {

  const transferSFTPFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.Endpoint, Validators.required),
      key: 'Endpoint',
      label: 'Endpoint'
    }),
    new LipoFormPassword({
      value: new FormControl(model.GatewayPassword, Validators.required),
      key: 'GatewayPassword',
      label: 'GatewayPassword'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantId, Validators.required),
      key: 'ParticipantId',
      label: 'ParticipantId'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.ParticipantPartnerId, Validators.required),
      key: 'ParticipantPartnerId',
      label: 'ParticipantPartnerId'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.FtpPassword, Validators.required),
      key: 'FtpPassword',
      label: 'FtpPassword'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.BaseFolder, Validators.required),
      key: 'BaseFolder',
      label: 'BaseFolder'
    }),
    new LipoFormTextbox({
      value: new FormControl(model.BaseSubFolder, Validators.required),
      key: 'BaseSubFolder',
      label: 'BaseSubFolder'
    }),
  ]

  return scheduled([
    new LipoFormGroupModel({
      key: settings.formKey,
      label: 'Transfer SFTP',
      order: 2,
      controls: transferSFTPFields,
      expansionActive: true,
      expansion: {background: true, hideToggle: true, expanded: true, disabled: true}
    }),
  ], asyncScheduler);
}

