import {Component, OnInit} from '@angular/core';
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {Router} from "@angular/router";
import {SystemDataService} from "../../services/system-data.service";
import {SystemServiceMapper} from "../../mappers/system-service.mapper";
import {SystemOverviewMapper} from "../../mappers/system-overview.mapper";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {ProgressService} from "../../../shared/services/progress.service";
import {catchError, forkJoin, of} from "rxjs";

@Component({
  selector: 'du-system-overview',
  standalone: true,
    imports: [
        LipoOverviewComponent
    ],
  templateUrl: './system-overview.component.html',
  styleUrl: './system-overview.component.scss'
})
export class SystemOverviewComponent implements OnInit{
  systemOverviewModel?: LipoOverviewModel;

  protected readonly LipoRouteEnum = LipoRouteEnum;

  constructor(
    private systemService: SystemDataService,
    protected router: Router,
    private _progressService: ProgressService,
  ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this.systemService.getSystemsSmall().subscribe({
      next: system => {
        let systemsOverview = system.map(SystemServiceMapper.toSystemOverviewModelSmall);

        const heartbeatRequests = systemsOverview.map(system => {
          if (system.sapSystemId) {
            return this.systemService.getSystemAliveStatus(system.sapSystemId).pipe(
              catchError(() => of(false))
            );
          } else {
            return of(false);
          }
        });

        forkJoin(heartbeatRequests).subscribe({
          next: aliveStatus => {
            aliveStatus.forEach((isAlive, index) => {
              systemsOverview[index].isAlive = isAlive;
            });

        let systemCards = systemsOverview.map(SystemOverviewMapper.toLipoCard);
        let systemTable = SystemOverviewMapper.toLipoTable(systemsOverview, this.router);

        this.systemOverviewModel = {
          title: 'system',
          buttons: LipoButton.build({
            text: "button.system.add",
            icon: "add",
            onClick: async () => await this.router.navigate([LipoRouteEnum.SYSTEM, 'new'])
          }),
          cards: systemCards,
          table: systemTable
        }
      },
      complete: () => this._progressService.stopLoading()
    });
  }});
  }
}
