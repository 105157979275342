import {Component, inject, Input, OnInit} from '@angular/core';
import {MatChipsModule} from "@angular/material/chips";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatIconModule} from "@angular/material/icon";
import {LipoCardComponent} from "../lipo-card/lipo-card.component";
import {LipoOverviewModel} from "../models/lipo-overview.model";
import {TranslateModule} from "@ngx-translate/core";
import {I18nPluralPipe, NgIf, TitleCasePipe, UpperCasePipe} from "@angular/common";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatTableDataSource} from "@angular/material/table";
import {LipoOverviewSkeletonComponent} from "../lipo-overview-skeleton/lipo-overview-skeleton.component";
import {ProgressService} from "../../services/progress.service";
import {LipoOverviewEmptyComponent} from "../lipo-overview-empty/lipo-overview-empty.component";
import {LipoDetailTableComponent} from "../lipo-table/lipo-detail-table.component";
import {MatCard} from "@angular/material/card";

@Component({
  selector: 'du-lipo-overview',
  standalone: true,
  imports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    LipoCardComponent,
    TranslateModule,
    I18nPluralPipe,
    UpperCasePipe,
    TitleCasePipe,
    MatGridListModule,
    LipoOverviewSkeletonComponent,
    NgIf,
    LipoOverviewEmptyComponent,
    LipoDetailTableComponent,
    MatCard
  ],
  templateUrl: './lipo-overview.component.html',
  styleUrl: './lipo-overview.component.scss'
})
export class LipoOverviewComponent implements OnInit{
  @Input() cardDisabled: boolean = false
  @Input() overviewModel?: LipoOverviewModel;

  protected readonly _progressService = inject(ProgressService)
  searchInput: string = '';
  cardDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  viewType: 'grid' | 'list' = 'grid';

  ngOnInit(): void {
    const savedViewType = localStorage.getItem('lipo-overview-view-type');
    if (savedViewType === 'grid' || savedViewType === 'list') {
      this.viewType = savedViewType;
    }

      this.cardDataSource.filterPredicate = (data, filter: string): boolean => {
        const searchTerm = filter.trim().toLowerCase();

        const rowData = Object.keys(data)
          .filter(key => key !== 'route')
          .map(key => {
            const value = data[key];
            if (value == null) return '';
            if (typeof value === 'object') {
              return JSON.stringify(value).toLowerCase();
            }
            return value.toString().toLowerCase();
          })
          .join(' ');

        return rowData.includes(searchTerm);
      };
  }

  get cardsDataSource() {
    if (this.overviewModel?.cards) {
      this.cardDataSource.data = this.overviewModel?.cards
    }

    return this.cardDataSource;
  }

  applyFilter() {
    let searchString = this.searchInput.trim().toLowerCase();
    this.cardDataSource.filter = searchString;
    this.overviewModel!.table.tableDataSource.filter = searchString;
  }

  toggleViewType(view: 'grid' | 'list') {
    this.viewType = view;
    localStorage.setItem('lipo-overview-view-type', view);
  }
}
