import {PartnerOverviewModel} from "../components/models/partner-overview.model";
import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";
import {Router} from "@angular/router";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../shared/components/models/lipo-table.model";
import {LipoTableImageComponent} from "../../shared/components/lipo-table-image/lipo-table-image.component";
import {LipoAmountBadgesComponent} from "../../shared/components/lipo-amount-badges/lipo-amount-badges.component";
import {MatTableDataSource} from "@angular/material/table";
import {LipoModelInterface} from "../../shared/interfaces/lipo-model.interface";

export class PartnerOverviewMapper {
  public static toLipoCardModel(partnerOverviewModel: PartnerOverviewModel): LipoCardModel {
    return new LipoCardModel({
      id: partnerOverviewModel.id,
      route: {commands: [LipoRouteEnum.PARTNER, partnerOverviewModel.id]},
      title: partnerOverviewModel.name,
      logoUrl: partnerOverviewModel.logoUrl,
      badges: [
        {amount: partnerOverviewModel.licenses, label: 'licenses', routerLink: ['',  LipoRouteEnum.LICENCE]},
        {amount: partnerOverviewModel.customers, label: 'customers', routerLink: ['',  LipoRouteEnum.CUSTOMER]},
        {amount: partnerOverviewModel.systems, label: 'systems', routerLink: ['',  LipoRouteEnum.SYSTEM]}
      ],
    });
  }

  public static toLipoTable(partnerOverviewModel: PartnerOverviewModel[], router: Router): LipoTableModel<any> {
    let updatedValues = partnerOverviewModel.map(partner => {
      return {
        ...partner,
        lipoTableImageComponentInputs: {logoUrl: partner.logoUrl} as LipoTableImageComponent,
        lipoCompanyBadgesComponent: {
          badges: [
            {amount: partner.licenses, label: 'licenses', routerLink: ['',  LipoRouteEnum.LICENCE]},
            {amount: partner.customers, label: 'customers', routerLink: ['',  LipoRouteEnum.CUSTOMER]},
            {amount: partner.systems, label: 'systems', routerLink: ['',  LipoRouteEnum.SYSTEM]}
          ],
        } as LipoAmountBadgesComponent,
        getId: () => partner.id,
      };
    });

    let tableDataSource = new MatTableDataSource<LipoModelInterface>(updatedValues);
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: '', PropertyName: 'lipoTableImageComponentInputs', Component: LipoTableImageComponent},
      {HeaderCellName: 'partner', PropertyName: 'name'},
      {HeaderCellName: 'phone', PropertyName: 'phone'},
      {HeaderCellName: 'mail', PropertyName: 'mail'},
      {HeaderCellName: '', PropertyName: 'lipoCompanyBadgesComponent', Component: LipoAmountBadgesComponent},
    );

    return new LipoTableModel(
      tableDataSource,
      displayedColumns,
      undefined,
      undefined,
      async (value: LipoModelInterface) => {
        await router.navigate([LipoRouteEnum.PARTNER, value.getId()])
      },
      {header: true, headerAction: false}
    );
  }
}
