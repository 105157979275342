import {
  AppLicenseSettingsConfiguration,
  AribaSettingsConfiguration,
  EBillSettingsConfiguration
} from "../services/models/configuration.model";
import {FormGroup} from "@angular/forms";
import {
  CompanyPoliciesModel,
  MobileLogisticsSettingsModel
} from "../../tenant/components/models/mobile-logistics-settings.model";
import {
  ShopifySettingsModel,
  ShopwareSettingsModel,
  StoreLinkSettingsModel
} from "../../tenant/components/models/store-link-settings.model";
import {mapToStoreLinkSettingsServiceModel} from "../../tenant/mappers/store-link.mapper";
import {YellowBillMailModel, YellowBillRestModel} from "../../tenant/components/models/ebill/yellow-bill.model";
import {
  ZugFerDEdifactModel,
  ZugFerDMailModel,
  ZugFerDRestModel
} from "../../tenant/components/models/ebill/zug-ferd.model";
import {AribaInboundModel, AribaOutboundModel} from "../../tenant/components/models/ariba.model";
import {TransferSFTPModel} from "../../tenant/components/models/transfereSFTP.model";

export function AppSettingsFormMapper(formGroup: FormGroup, appName: string | null, appSettings: AppLicenseSettingsConfiguration): any {
  switch (appName) {
    case appSettings.mobileLogistic.formKey: {
      let mobileLogisticControl = formGroup.get(`${appSettings.mobileLogistic.formKey}_base`)
      let mobileLogisticModel = mobileLogisticControl?.getRawValue() as MobileLogisticsSettingsModel

      mobileLogisticModel.Subscriptions = mobileLogisticControl?.get('Subscriptions')?.getRawValue().join('|')
      mobileLogisticModel.CompanyPolicies = formGroup.get(`${appSettings.mobileLogistic.formKey}_companyPolicy`)?.getRawValue() as CompanyPoliciesModel

      return mobileLogisticModel
    }
    case appSettings.storeLink.formKey: {
      let storeLinkControl = formGroup.get(`${appSettings.storeLink.formKey}_base`)
      let storeLinkBaseModel = storeLinkControl?.getRawValue() as StoreLinkSettingsModel
      let storeLinkOptional = formGroup.get(`${appSettings.storeLink.formKey}_optional`)?.getRawValue() as StoreLinkSettingsModel
      let shopify = formGroup.get(`${appSettings.storeLink.formKey}_shopify`)?.getRawValue() as ShopifySettingsModel
      let shopware = formGroup.get(`${appSettings.storeLink.formKey}_shopware`)?.getRawValue() as ShopwareSettingsModel

      let storeLinkModel = new StoreLinkSettingsModel(
        storeLinkBaseModel.JSONataHost,
        storeLinkBaseModel.JSONataUser,
        storeLinkBaseModel.JSONataPassword,
        storeLinkBaseModel.JsonPath,
        storeLinkOptional.DefaultPriceList,
        storeLinkOptional.DefaultWarehouse,
        storeLinkOptional.PrePaymentCreateReservedInvoice,
        storeLinkOptional.PrePaymentCreateDownPayment,
        storeLinkOptional.PrePaymentCreateIncomingPayment,
      )

      return mapToStoreLinkSettingsServiceModel(storeLinkModel, shopware, shopify)
    }

    case appSettings.transferSFTP.formKey: {
      return Object.assign(
        new TransferSFTPModel(),
        formGroup.get(appSettings.transferSFTP.formKey)?.getRawValue()
      );
    }
  }

  if(appName?.includes(appSettings.eBill.formKey)) {
    return handleEBillAppName(appName, formGroup, appSettings.eBill);
  } else if (appName?.includes(appSettings.ariba.formKey)) {
    return handleAribaAppName(appName, formGroup, appSettings.ariba);
  }

  throw new Error(`Unknown app name: ${appName}`);
}

function handleEBillAppName(
  appNameValue: string,
  formGroup: FormGroup,
  eBillConfig: EBillSettingsConfiguration
) {
  if (appNameValue?.includes(eBillConfig.yellowBillRest.formKey)) {
    return Object.assign(
      new YellowBillRestModel(),
      formGroup.get(`${eBillConfig.formKey}_${eBillConfig.yellowBillRest.formKey}_base`)?.getRawValue()
    );
  }

  if (appNameValue?.includes(eBillConfig.yellowBillMail.formKey)) {
    return Object.assign(
      new YellowBillMailModel(),
      formGroup.get(`${eBillConfig.formKey}_${eBillConfig.yellowBillMail.formKey}_base`)?.getRawValue() as YellowBillMailModel
    );
  }

  if (appNameValue?.includes(eBillConfig.zugFerDRest.formKey)) {
    return Object.assign(
      new ZugFerDRestModel(),
      formGroup.get(`${eBillConfig.formKey}_${eBillConfig.zugFerDRest.formKey}_base`)?.getRawValue() as ZugFerDRestModel
    );
  }

  if (appNameValue?.includes(eBillConfig.zugFerDMail.formKey)) {
    return Object.assign(
      new ZugFerDMailModel(),
      formGroup.get(`${eBillConfig.formKey}_${eBillConfig.zugFerDMail.formKey}_base`)?.getRawValue() as ZugFerDMailModel
    );
  }

  if (appNameValue?.includes(eBillConfig.zugFerDEdifact.formKey)) {
    return Object.assign(
      new ZugFerDEdifactModel(),
      formGroup.get(`${eBillConfig.formKey}_${eBillConfig.zugFerDEdifact.formKey}_base`)?.getRawValue() as ZugFerDEdifactModel
    );
  }

  return undefined;
}

function handleAribaAppName(
  appNameValue: string,
  formGroup: FormGroup,
  aribaConfig: AribaSettingsConfiguration
) {
  if (appNameValue?.includes(aribaConfig.inbound.formKey)) {
    return Object.assign(
      new AribaInboundModel(),
      formGroup.get(`${aribaConfig.formKey}_${aribaConfig.inbound.formKey}`)?.getRawValue()
    );
  }

  if (appNameValue?.includes(aribaConfig.outbound.formKey)) {
    return Object.assign(
      new AribaOutboundModel(),
      formGroup.get(`${aribaConfig.formKey}_${aribaConfig.outbound.formKey}`)?.getRawValue()
    );
  }
  return undefined;
}
