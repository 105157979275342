import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, PipeTransform, ViewChild} from '@angular/core';
import {MatTableModule} from "@angular/material/table";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {LipoTableModel} from "../models/lipo-table.model";
import {LipoModelInterface} from "../../interfaces/lipo-model.interface";
import {FormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {TranslateModule, TranslatePipe, TranslateService} from "@ngx-translate/core";
import {NgClass, NgComponentOutlet, UpperCasePipe} from "@angular/common";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";

@Component({
  selector: 'du-lipo-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatCheckboxModule,
    FormsModule,
    MatButton,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    TranslateModule,
    UpperCasePipe,
    MatSortModule,
    MatPaginatorModule,
    NgClass,
    NgComponentOutlet,
  ],
  templateUrl: './lipo-detail-table.component.html',
  styleUrl: './lipo-detail-table.component.scss'
})
export class LipoDetailTableComponent implements OnInit, AfterViewInit {
  @Input() lipoTable!: LipoTableModel<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  searchInput: string = '';
  columnsToDisplay: string[] = [];

  constructor(
    private _translate: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.columnsToDisplay = this.lipoTable.displayedColumns.map(value => value.PropertyName);
    if (this.lipoTable.selectionModel) {
      this.columnsToDisplay.unshift('select');
    }

    this.lipoTable.tableDataSource.filterPredicate = (data, filter: string): boolean => {
      const searchTerm = filter.trim().toLowerCase();
      return Object.values(data).some(value => {
        return value != null && value.toString().toLowerCase().includes(searchTerm);
      });
    };

  }


  ngAfterViewInit(): void {
    this.lipoTable.tableDataSource.paginator = this.paginator;
    this.lipoTable.tableDataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.lipoTable.selectionModel?.selected.length;
    const numRows = this.lipoTable.tableDataSource.data.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.lipoTable.selectionModel?.clear();
      return;
    }

    this.lipoTable.selectionModel?.select(...this.lipoTable.tableDataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.lipoTable.selectionModel?.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  async handleClickEvent(row: LipoModelInterface) {
    if (this.lipoTable.onRowClick) {
      await this.lipoTable.onRowClick(row);
    }
  }

  pipeValue(pipes: PipeTransform[], value: any) {
    if (value === null || value === undefined) return '';

    let pipesToFormat = [...pipes];
    pipesToFormat.push(new TranslatePipe(this._translate, this._changeDetectorRef));

    return pipesToFormat.reduce((prevValue, currPipe) => {
      try {
        return currPipe.transform(String(prevValue));
      } catch (e) {
        return prevValue;
      }
    }, String(value));
  }

  applyFilter() {
    this.lipoTable.tableDataSource.filter = this.searchInput.trim().toLowerCase();
  }

}
