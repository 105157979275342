import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";

export class TenantAppSettingsDetailModel implements LipoModelInterface {

  constructor(
    public appName: string | null = null,
    public description: string = '',
    public attributes: any | null = null,
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}
